import React from 'react';
import { connect } from 'react-redux';
import {
  IonItem,
  IonCol,
  IonGrid,
  IonRow,
  IonInput,
  IonCheckbox,
  IonButton,
  IonAlert,
} from '@ionic/react';
import Basket from '../../lib/basket';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import { NormalText, Spacer, StrongText } from '../../components/common';
import './index.css';
import Loading from '../../components/spinner';
import TableItemsOverview from '../../components/tableItemsOverview';
import TableBillServiceCharge from '../../components/tableBillServiceCharge';
import { forwardTo, isEmptyArray, isEmptyObject } from '../../lib/utils';
import { selectTableItems } from '../../store/actions';
import CheckoutPay from '../checkout/CheckoutPay';
import DrawPayButton from '../../components/drawPayButton';
import { Capacitor, Plugins } from '@capacitor/core';
import { getConfig } from '../../appConfig';
import { SET_TABLE_PAY_METHOD } from '../../store/constants';
const isWeb = () => Capacitor.platform === 'web';
const { SelectOption } = Mobiscroll;

class SplitBillByItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsPrice: 0,
      paidItems: [],
      updatedItems: [],
      selectedItems: [],
      isSetPayMethodModalOpen: false,
    };
  }
  setTablePayMethod = () => {
    Basket.setBillPayServiceCharge(0);
    Basket.setPortionToPay(0);
    this.props.dispatch({
      type: SET_TABLE_PAY_METHOD,
      data: {
        pay_method: 2,
        table_bill_id: Basket.bill_data.id,
      },
    });
  };
  handleInputChange = (e, item) => {
    const paidItems = Basket.paid_items;
    let updatedItems = [];
    if (e.target.checked) {
      updatedItems.push(...paidItems, item);
      // Basket.getTableItemPrice();
      this.props.dispatch(
        selectTableItems({
          tableNumber: Basket.getPayTableNumber(),
          businessLocationId: Basket.getRestaurant().pos_location_id,
          profileId: this.props.profile.id,

          selected_item: {
            user_id: this.props.profile.id,
            item_id: item.id,
            selected: true,
          },
        }),
      );
    } else {
      const removedItem = paidItems.filter((el) => el.id !== item.id);
      updatedItems = removedItem;
      this.props.dispatch(
        selectTableItems({
          tableNumber: Basket.getPayTableNumber(),
          businessLocationId: Basket.getRestaurant().pos_location_id,
          profileId: this.props.profile.id,
          selected_item: {
            user_id: this.props.profile.id,
            item_id: item.id,
            selected: false,
          },
        }),
      );
    }
    this.setState({ selectedItems: updatedItems }, () => {
      const {
        automaticServiceChargePercentage,
        automaticServiceChargeValue,
      } = this.calculateServiceCharge(Basket.paid_items);
      this.setState({ automaticServiceChargePercentage, automaticServiceChargeValue });
    });
  };
  onServiceChargeSetHandler = (serviceChargeValue) => {
    this.setState({ serviceChargeValue });
  };
  componentDidMount() {
    const { google } = window;
    if (google) {
      window.googlePayClient = new google.payments.api.PaymentsClient({
        environment: 'PRODUCTION',
      });
    }
    Basket.calculateItemsPrice(this.props.profile.id);
    const {
      automaticServiceChargePercentage,
      automaticServiceChargeValue,
    } = this.calculateServiceCharge(Basket.paid_items);
    this.setState({ automaticServiceChargePercentage, automaticServiceChargeValue });
  }
  calculateServiceCharge = (items) => {
    let total = 0;
    let totalPlusServiceCharge = 0;
    for (let i = 0; i < items.length; i++) {
      total = total + parseFloat(items[i].grossUnitAmount);
      totalPlusServiceCharge = totalPlusServiceCharge + parseFloat(items[i].unitAmount);
      for (let j = 0; j < items[i].subLineItems.length; j++) {
        total = total + parseFloat(items[i].subLineItems[j].grossUnitAmount);
        totalPlusServiceCharge =
          totalPlusServiceCharge + parseFloat(items[i].subLineItems[j].unitAmount);
      }
    }
    if (total > 0) {
      const automaticServiceChargeValue = totalPlusServiceCharge - total;
      const num =
        ((parseFloat(totalPlusServiceCharge) - parseFloat(total)) / parseFloat(total)) * 100;
      const automaticServiceChargePercentage = `${Math.floor(num * 10) / 10}%`;
      return { automaticServiceChargePercentage, automaticServiceChargeValue };
    } else {
      return {
        automaticServiceChargePercentage: 0,
        automaticServiceChargeValue: '0%',
      };
    }
  };
  render() {
    const { __, clientSecret, profile, location, dispatch, tableData } = this.props;
    const { serviceChargeValue } = this.state;
    const deliveryOption = Basket.getDeliveryOption();
    const tableItems = Basket.getTableItems();
    const data = !isEmptyObject(tableData) ? tableData : Basket.bill_data;
    return (
      <Loading transparent>
        <Layout headerTitle={__(deliveryOption ? deliveryOption.label : '')} color="transparent">
          <div className="absolute-content scrollable-y">
            <div className="bill-pay-title-wrapper">
              <NormalText className="block">
                {__('Table')} {Basket.getBillTableNumber()}
              </NormalText>
              <IonButton className="link underlined" onClick={() => forwardTo('/table-bill-pay')}>
                {__('Wrong table?')}
              </IonButton>
            </div>
            <Spacer size={1} />
            <div className="table-items-to-pay-wrapper box-wrapper">
              {tableItems.map((item, index) => {
                const isChecked = !!data?.selected_items?.find(
                  (el) => el.item_id == item.id && el.user_id == this.props.profile.id,
                );
                const isDisabled =
                  !!data?.paid_items?.find((el) => el.id == item.id) ||
                  !!data?.selected_items?.find(
                    (el) => el.item_id == item.id && el.user_id !== this.props.profile.id,
                  );
                return (
                  item.quantity > 0 && (
                    <div key={index}>
                      <IonItem lines="none" key={index} className="table-item-wrapper">
                        {parseFloat(item.unitAmount) > 0 && (
                          <IonCheckbox
                            disabled={isDisabled}
                            color="secondary"
                            slot="start"
                            onIonChange={(event) => this.handleInputChange(event, item)}
                            value={item.unitAmount * item.quantity}
                            checked={isChecked}
                          />
                        )}
                        <IonGrid style={{ width: '100%' }}>
                          <IonRow className={isDisabled ? 'disabled' : ''}>
                            <IonCol
                              className={parseFloat(item.grossUnitAmount) > 0 ? '' : 'empty-item'}
                            >
                              <NormalText>
                                {item.quantity} x {__(item.itemName)}
                              </NormalText>
                            </IonCol>
                            <IonCol className="righted">
                              <NormalText>
                                {Basket.formatPrice(item.grossUnitAmount * item.quantity, true)}
                              </NormalText>
                            </IonCol>
                          </IonRow>
                          {!isEmptyArray(item.subLineItems)
                            ? item.subLineItems.map((subitem) => {
                                return (
                                  <IonRow
                                    className={
                                      isDisabled
                                        ? 'disabled subitem pay-items'
                                        : 'subitem pay-items'
                                    }
                                    key={subitem.id}
                                  >
                                    <IonCol>
                                      <NormalText>
                                        {subitem.quantity} x {subitem.itemName}
                                      </NormalText>
                                    </IonCol>
                                    <IonCol className="righted">
                                      <NormalText>
                                        {Basket.formatPrice(
                                          subitem.grossUnitAmount * subitem.quantity,
                                        )}
                                      </NormalText>
                                    </IonCol>
                                  </IonRow>
                                );
                              })
                            : null}
                        </IonGrid>
                      </IonItem>
                    </div>
                  )
                );
              })}
              {this.state.automaticServiceChargeValue > 0 && (
                <div style={{ padding: '5px' }}>
                  <IonRow>
                    <IonCol size="8">
                      {`${__('Service Charge')} ${this.state.automaticServiceChargePercentage}`}
                    </IonCol>
                    <IonCol className="righted" size="4">
                      {Basket.formatPrice(this.state.automaticServiceChargeValue)}
                    </IonCol>
                  </IonRow>
                </div>
              )}
            </div>
            <TableBillServiceCharge serviceChargeSet={this.onServiceChargeSetHandler} />
            <Spacer size={1} />
            <>
              <IonGrid className="box-wrapper space-between pointer ">
                <IonRow>
                  <IonCol>{__('Payment Amount')}</IonCol>
                  <IonCol className="righted">{Basket.getTotalPlusServiceCharge()}</IonCol>
                </IonRow>
              </IonGrid>
              <Spacer size={1} />
            </>
            <>
              {isWeb() ? (
                <>
                  {Basket.bill_pay_total > 0 && (
                    <CheckoutPay
                      clientSecret={clientSecret}
                      dispatch={dispatch}
                      __={__}
                      location={location}
                      profile={profile}
                      isBillPay={true}
                      billTotal={Basket.bill_pay_total}
                    />
                  )}
                </>
              ) : (
                <>
                  {getConfig().billPay.hasAppleAndGPay && (
                    <DrawPayButton
                      isBillPay={true}
                      __={__}
                      googlePayClient={window.googlePayClient}
                    />
                  )}
                </>
              )}
            </>
            <IonButton
              disabled={Basket.bill_pay_total == 0}
              fill="outline"
              color="secondary"
              expand="block"
              onClick={() => forwardTo('/checkout', { fromTablePay: true })}
            >
              {__('Pay with card')}
            </IonButton>
            <IonButton
              expand="block"
              fill="clear"
              color="secondary"
              className="link underlined"
              onClick={() => this.setState({ isSetPayMethodModalOpen: true })}
            >
              {__('Or pay another amount')}
            </IonButton>
          </div>
          <IonAlert
            isOpen={this.state.isSetPayMethodModalOpen}
            onDidDismiss={() => this.setState({ isSetPayMethodModalOpen: false })}
            header={__('Are you sure you want to set this?')}
            // message={__(this.getRestaurantDataMessage(__))}
            buttons={[
              {
                text: __('Cancel'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => this.setState({ isSetPayMethodModalOpen: false }),
              },
              {
                text: __('Confirm'),
                role: 'submit',
                cssClass: 'secondary',
                handler: () => this.setTablePayMethod(),
              },
            ]}
          />
        </Layout>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const { tableData } = store.restaurants;
  const { myLocation } = store.common;
  const { auth, profile } = store.profile;
  const { clientSecret } = store.orders;

  return {
    tableData,
    myLocation,
    auth,
    profile,
    clientSecret,
  };
};

export default connect(mapStateToProps)(withTranslation(SplitBillByItems));
