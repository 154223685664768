import React from 'react';
import { connect } from 'react-redux';
import Modal from '../../components/modal';
import { Title, Spacer, NormalText, SmallText, StrongText } from '../../components/common';
import { isDefined, isWebConfig, validateProfileData } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { forwardTo } from '../../lib/utils';
import QRCode from 'qrcode-react';
import moment from '../../lib/moment';
import './index.css';
import { lockClosed } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import ValidateButton from '../validateButton';

const RewardItem = ({ reward, id, __, action, available_balance }) => {
  return (
    <div
      onClick={() => available_balance >= reward.cost && action(id)}
      className="loyalty-reward"
      style={{ backgroundImage: `url(${reward.image})` }}
    >
      {available_balance < reward.cost ? (
        <div className="reward-cover">
          <IonIcon icon={lockClosed} color="white" />
        </div>
      ) : null}
      <div className="loyalty-reward-name">
        <StrongText>{`${reward.cost} ${__('Points')}\n`}</StrongText>
        <NormalText>{reward.name}</NormalText>
      </div>
    </div>
  );
};

class UnlockRewardItmes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReward: null,
    };
  }

  viewReward = (id) => {
    const { rewards } = this.props;
    this.setState({ selectedReward: rewards.find((r) => r.id === id) });
  };

  render() {
    const { __, qr_code, available_balance, rewards, auth, profile } = this.props;
    const { selectedReward } = this.state;
    const isAuth = auth.loggedIn;
    const valid = validateProfileData(profile).isValid;
    return (
      <>
        <>
          {(rewards || []).map((reward, index) => {
            return (
              <RewardItem
                key={'rw-' + index}
                {...reward.reward}
                __={__}
                available_balance={available_balance}
                action={() => this.viewReward(reward.id)}
              />
            );
          })}
        </>
        <Modal
          className="reward-modal"
          isOpen={selectedReward !== null}
          onDidDismiss={() => this.setState({ selectedReward: null })}
          title={__('Voucher Info')}
        >
          {selectedReward?.reward || selectedReward?.reward?.reward ? (
            <div className="centered">
              <img
                alt=""
                src={selectedReward.reward.image || selectedReward.reward.reward.image}
                width="100%"
                height={
                  selectedReward.reward.image || selectedReward.reward.reward.image !== null
                    ? '200px'
                    : ''
                }
              />
              <Spacer size={2} />
              <div className="reward-modal-content">
                {selectedReward.reward.name || selectedReward.reward.reward.name ? (
                  <>
                    <Title className="uppercase bold">
                      {selectedReward.reward.name || selectedReward.reward.reward.name}
                    </Title>
                    <Spacer />
                  </>
                ) : null}
                {selectedReward.reward.small_print || selectedReward.reward.reward.small_print ? (
                  <div>
                    <NormalText>
                      {selectedReward.reward.small_print ||
                        selectedReward.reward.reward.small_print}
                    </NormalText>
                  </div>
                ) : null}
                {selectedReward.reward.expiry_date || selectedReward.reward.reward.expiry_date ? (
                  <div>
                    <NormalText>
                      {__('Expires:')}{' '}
                      {moment(
                        selectedReward.reward.expiry_date ||
                          selectedReward.reward.reward.expiry_date,
                        'YYYY/MM/DD',
                      ).format('DD/MM/YY')}
                    </NormalText>
                  </div>
                ) : null}
                <Spacer size={1} />
                <div onClick={() => forwardTo('/terms')}>
                  <NormalText className="bold">{__('Terms & Conditions')}</NormalText>
                </div>
                <Spacer size={1} />
                {selectedReward.reward.description || selectedReward.reward.reward.description ? (
                  <div>
                    <SmallText>
                      {__(
                        selectedReward.reward.description ||
                          selectedReward.reward.reward.description,
                      )}
                    </SmallText>
                  </div>
                ) : null}
                <>
                  <Spacer />
                  {isAuth && valid ? (
                    isDefined(profile.qr_code) ? (
                      <div className="qr-holder">
                        <QRCode value={profile.qr_code} size={isWebConfig() ? 150 : 170} />
                      </div>
                    ) : (
                      <div>
                        <h5>{__('NO QR CODE')}</h5>
                      </div>
                    )
                  ) : (
                    <ValidateButton />
                  )}
                  <Spacer />
                </>
              </div>
            </div>
          ) : null}
        </Modal>
      </>
    );
  }
}

const stateToProps = (state) => {
  const { profile, auth } = state.profile;
  return {
    vouchers: state.profile.vouchers || [],
    qr_code: state.profile.profile.qr_code,
    profile,
    auth: auth,
  };
};

export default connect(stateToProps)(withTranslation(UnlockRewardItmes));
