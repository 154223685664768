import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonItem, IonLabel, IonInput, IonList, IonToggle } from '@ionic/react';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { FieldError, Title, SmallText, NormalText, Spacer } from '../../components/common';
import { validateForm, forwardTo } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { setGuestUser, updateProfile } from '../../store/actions';
import Basket from '../../lib/basket';
import './index.css';
import CreateAccount from '../createAccount';
import Modal from '../../components/modal';

const { getMobile, setMobile } = Basket;
const HIDE_BACK = true;
const HIDE_TITLE = true;
class ContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: this.props.profile.first_name || '',
      last_name: this.props.profile.last_name || '',
      mobile: getMobile() || this.props.profile.mobile || '',
      formErrors: {},
      guest_first_name: this.props.guestUserData.guest_first_name || '',
      guest_email: this.props.guestUserData.guest_email || '',
      guest_accepted_terms_and_conditions:
        this.props.guestUserData.guest_accepted_terms_and_conditions || false,
      guest_mobile: this.props.guestUserData.guest_mobile || '',
      guestFormErrors: {},
      isCreateAccountModalOpen: false,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.formConfig = {
      first_name: { type: 'first_name', required: true },
      mobile: { type: 'tel', required: true },
    };
    this.guestValidationConfig = {
      guest_first_name: { type: 'first_name', required: true },
      guest_mobile: { type: 'tel', required: true },
      guest_email: { type: 'email', required: true },
      guest_accepted_terms_and_conditions: { type: 'toggle', toggle: true },
    };
  }

  componentDidMount() {
    if (this.state.first_name !== '' && this.state.mobile !== '') {
      forwardTo('/checkout', { skipContactDetails: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.first_name !== prevProps.profile.first_name) {
      this.setState({ first_name: this.props.profile.first_name });
    }
    if (this.props.profile.last_name !== prevProps.profile.last_name) {
      this.setState({ last_name: this.props.profile.last_name });
    }
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({
        mobile: this.props.profile.mobile ? this.props.profile.mobile : getMobile(),
      });
    }
  }

  handleInput = (key, val) => {
    if (key === 'mobile' || key === 'guest_mobile') {
      setMobile(val);
    }
    this.setState({ [key]: val });
  };

  handleSave() {
    const { cards } = this.props;
    let formErrors = validateForm(this.formConfig, this.state);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { first_name, last_name, mobile } = this.state;
      const profile = {
        first_name: first_name,
        last_name: last_name,
        mobile: mobile ? mobile : getMobile(),
      };
      if (mobile) {
        this.props.dispatch(updateProfile(profile, true));
        if (cards && cards.length === 0) {
          forwardTo('/card-add');
        } else {
          forwardTo('/checkout');
        }
      } else {
        forwardTo('/checkout');
      }
    }
  }
  handleGuestSave = () => {
    const { dispatch } = this.props;
    let guestFormErrors = validateForm(this.guestValidationConfig, this.state);
    this.setState({ guestFormErrors });

    if (Object.keys(guestFormErrors).length === 0) {
      const {
        guest_email,
        guest_first_name,
        guest_mobile,
        guest_accepted_terms_and_conditions,
      } = this.state;

      const guestUserData = {
        guest_email,
        guest_first_name,
        guest_mobile,
        guest_accepted_terms_and_conditions,
      };
      dispatch(setGuestUser(guestUserData));
      this.setState({ isCreateAccountModalOpen: true });
    }
  };

  render() {
    const { __, auth } = this.props;
    const {
      first_name,
      last_name,
      mobile,
      formErrors,
      guest_first_name,
      guest_mobile,
      guest_email,
      guest_accepted_terms_and_conditions,
      guestFormErrors,
    } = this.state;

    return (
      <Loading transparent>
        <Layout color headerTitle="Contact Details">
          <div className="absolute-content flex-row-wrapper contact-details">
            <div className="scrollable-y checkout">
              <Title className="okx-font-secondary">{__('Contact Details')}</Title>
              <NormalText>
                {__(
                  'To complete your order, please provide a few details in case we need to contact you',
                )}
              </NormalText>
              {this.props.auth && this.props.auth.loggedIn ? (
                <IonList>
                  <IonItem lines="none">
                    <IonLabel position="floating">{__('Enter your first Name')}</IonLabel>
                    <IonInput
                      onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                      clearInput
                      required={true}
                      type="text"
                      pattern="text"
                      inputmode="text"
                      value={first_name}
                    ></IonInput>
                    {formErrors.first_name ? (
                      <FieldError className="field-error" value={__(formErrors.first_name)} />
                    ) : null}
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel position="floating">{__('Enter your Last Name')}</IonLabel>
                    <IonInput
                      onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                      clearInput
                      required={true}
                      type="text"
                      pattern="text"
                      inputmode="text"
                      value={last_name}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">{__('Enter your Mobile Number')}</IonLabel>
                    <IonInput
                      onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                      clearInput
                      required={true}
                      type="tel"
                      pattern="tel"
                      inputmode="tel"
                      value={mobile}
                    ></IonInput>
                    {formErrors.mobile ? (
                      <FieldError className="field-error" value={__(formErrors.mobile)} />
                    ) : null}
                  </IonItem>
                </IonList>
              ) : (
                <IonList>
                  <IonItem>
                    <IonLabel position="floating">{__('Enter your First Name')}</IonLabel>
                    <IonInput
                      onIonChange={(e) => this.handleInput('guest_first_name', e.target.value)}
                      clearInput
                      required={true}
                      type="text"
                      pattern="text"
                      inputmode="text"
                      value={guest_first_name}
                    ></IonInput>
                    {guestFormErrors.guest_first_name ? (
                      <FieldError
                        className="field-error"
                        value={__(guestFormErrors.guest_first_name)}
                      />
                    ) : null}
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">{__('Enter your Email')}</IonLabel>
                    <IonInput
                      onIonChange={(e) => this.handleInput('guest_email', e.target.value)}
                      clearInput
                      required={true}
                      type="text"
                      pattern="text"
                      inputmode="text"
                      value={guest_email}
                    ></IonInput>
                    {guestFormErrors.guest_email ? (
                      <FieldError className="field-error" value={__(guestFormErrors.guest_email)} />
                    ) : null}
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">{__('Enter your Mobile Number')}</IonLabel>
                    <IonInput
                      onIonChange={(e) => this.handleInput('guest_mobile', e.target.value)}
                      clearInput
                      required={true}
                      type="tel"
                      pattern="tel"
                      inputmode="tel"
                      value={guest_mobile}
                    ></IonInput>
                    {guestFormErrors.guest_mobile ? (
                      <FieldError
                        className="field-error"
                        value={__(guestFormErrors.guest_mobile)}
                      />
                    ) : null}
                  </IonItem>
                  <Spacer size={2} />
                  <IonItem className="guest-terms-and-conditions" lines="none">
                    <IonToggle
                      className="guest-terms-and-conditions-toggle"
                      slot="end"
                      checked={guest_accepted_terms_and_conditions}
                      onIonChange={(e) =>
                        this.handleInput('guest_accepted_terms_and_conditions', e.detail.checked)
                      }
                    />
                    <div className="toggle ">
                      <IonLabel>
                        <Title className="guest-terms-and-conditions-title">
                          {__('Terms & conditions')}
                        </Title>
                        <IonLabel className="ion-text-wrap">
                          <SmallText>
                            {__('By signing up you agree to our')}{' '}
                            <span className="link underlined" onClick={this.navToTerms}>
                              {__('terms and conditions')}
                            </span>{' '}
                            {__('and')}{' '}
                            <span className="link underlined" onClick={() => forwardTo('/privacy')}>
                              {__('privacy policy.')}
                            </span>
                          </SmallText>
                        </IonLabel>
                      </IonLabel>
                      <FieldError
                        className="field-error"
                        value={__(guestFormErrors.guest_accepted_terms_and_conditions)}
                      />
                    </div>
                  </IonItem>
                </IonList>
              )}
            </div>
            <Spacer size={1} />
            <div className="flex-min">
              <IonButton
                onClick={
                  this.props.auth && this.props.auth.loggedIn
                    ? this.handleSave
                    : this.handleGuestSave
                }
                expand="block"
                className="customized-button"
              >
                {`${__('Pay')} ${Basket._getTotal()}`}
              </IonButton>
            </div>
          </div>
        </Layout>
        <Modal
          className={`create-account-modal modal-is-${this.state.isCreateAccountModalOpen}`}
          isOpen={this.state.isCreateAccountModalOpen}
          onDidDismiss={() => this.setState({ isCreateAccountModalOpen: false })}
        >
          <CreateAccount hideBack={HIDE_BACK}></CreateAccount>
        </Modal>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, profile, guestUserData } = state.profile;
  const { cards } = state.orders;
  return {
    auth,
    profile,
    cards,
    guestUserData,
  };
};

export default connect(stateToProps)(withTranslation(ContactDetails));
