import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import React, { Component } from 'react';
import Basket from '../../lib/basket.js';
import { withTranslation } from '../../lib/translate.js';
import { NormalText, SmallText, StrongText } from '../common/index.js';
import { chevronDown, chevronUp } from 'ionicons/icons';

import './index.css';
import { connect } from 'react-redux';
import { SET_COMMON_PROP } from '../../store/constants.js';

class TableItemsOvewview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableItemsVisible: false,
    };
  }
  calculateServiceCharge = () => {
    let total = 0;
    let totalPlusServiceCharge = 0;
    for (let i = 0; i < Basket.table_items.length; i++) {
      total = total + parseFloat(Basket.table_items[i].grossUnitAmount);
      totalPlusServiceCharge =
        totalPlusServiceCharge + parseFloat(Basket.table_items[i].unitAmount);
      for (let j = 0; j < Basket.table_items[i].subLineItems.length; j++) {
        total = total + parseFloat(Basket.table_items[i].subLineItems[j].grossUnitAmount);
        totalPlusServiceCharge =
          totalPlusServiceCharge + parseFloat(Basket.table_items[i].subLineItems[j].unitAmount);
      }
    }
    const automaticServiceChargeValue = totalPlusServiceCharge - total;
    const num =
      ((parseFloat(totalPlusServiceCharge) - parseFloat(total)) / parseFloat(total)) * 100;
    const automaticServiceChargePercentage = `${Math.floor(num * 10) / 10}%`;
    return {
      automaticServiceChargePercentage,
      automaticServiceChargeValue,
    };
  };
  componentDidMount() {
    const {
      automaticServiceChargeValue,
      automaticServiceChargePercentage,
    } = this.calculateServiceCharge();
    this.setState({ automaticServiceChargeValue, automaticServiceChargePercentage });
  }
  render() {
    const { __, isTableItemsOverviewVisible, dispatch } = this.props;
    return (
      <IonGrid
        className={`${
          isTableItemsOverviewVisible ? 'items-overwiew-opened' : ''
        } box-wrapper space-between pointer table-items-wrapper`}
      >
        <IonRow
          style={{ width: '100%' }}
          onClick={() =>
            dispatch({
              type: SET_COMMON_PROP,
              key: 'isTableItemsOverviewVisible',
              value: !isTableItemsOverviewVisible,
            })
          }
        >
          <IonCol>
            <NormalText>
              {isTableItemsOverviewVisible ? __('Hide Items') : __('View Items')}
            </NormalText>
          </IonCol>
          <IonCol className="righted">
            <IonIcon icon={isTableItemsOverviewVisible ? chevronUp : chevronDown}></IonIcon>
          </IonCol>
        </IonRow>
        {isTableItemsOverviewVisible && (
          <>
            {Basket.table_items.map((el) => (
              <>
                <IonRow style={{ width: '100%' }}>
                  <IonCol size="8">
                    <NormalText>{el.itemName}</NormalText>
                  </IonCol>
                  <IonCol size="4" className="righted">
                    <NormalText>{Basket.formatPrice(el.grossUnitAmount)}</NormalText>
                  </IonCol>
                </IonRow>
                {el.subLineItems.map((subitem, i) => (
                  <IonRow key={`sub-${i}`} style={{ width: '100%', paddingLeft: '20px' }}>
                    <IonCol size="8">
                      <NormalText>{subitem.itemName}</NormalText>
                    </IonCol>
                    <IonCol size="4" className="righted">
                      <NormalText>{Basket.formatPrice(subitem.grossUnitAmount)}</NormalText>
                    </IonCol>
                  </IonRow>
                ))}
              </>
            ))}
            {this.state.automaticServiceChargeValue > 0 && (
              <IonRow>
                <IonCol>
                  {__('Service charge')} {this.state.automaticServiceChargePercentage}
                </IonCol>
                <IonCol className="righted">
                  {Basket.formatPrice(this.state.automaticServiceChargeValue)}
                </IonCol>
              </IonRow>
            )}
          </>
        )}
      </IonGrid>
    );
  }
}
const stateToProps = (state) => {
  const { isTableItemsOverviewVisible } = state.common;
  return {
    isTableItemsOverviewVisible,
  };
};
export default connect(stateToProps)(withTranslation(TableItemsOvewview));
