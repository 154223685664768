import { isWebConfig } from './lib/utils';
import Dashboard from './screens/dashboard';
import Loyalty from './screens/loyalty';
import Vouchers from './screens/vouchers';
import Account from './screens/account';
import ReferAFriend from './screens/referAFriend';
import Feedback from './screens/feedback';
import History from './screens/history';
import HistoryDetails from './screens/historyDetails';
import Locations from './screens/locations';
import Social from './screens/social';
import Login from './screens/login';
import Register from './screens/register';
import ResetPassword from './screens/resetPassword';
import Terms from './screens/terms';
import Privacy from './screens/privacy';
import Faq from './screens/faq';
// import Order from './screens/order'
import ItemDetails from './screens/itemDetails';
import DeliveryOptions from './screens/delivery-options';
import Delivery from './screens/delivery';
import DeliveryAddressCheck from './screens/delivery-address-check';
import DeliveryAddressAdd from './screens/delivery-address-add';
import DeliveryTime from './screens/delivery-time';
import PickUpPoint from './screens/pick-up-point';
import PickUpPointCheck from './screens/pick-up-point-check';
import ClickAndCollect from './screens/clickAndCollect';
import Cards from './screens/cards';
import CardAdd from './screens/cardAdd';
import OrderSummary from './screens/orderSummary';
import ApplyPoints from './screens/applyPoints';
import Checkout from './screens/checkout';
import OrderCompleted from './screens/orderCompleted';
import ContactDetails from './screens/contactDetails';
import OrderToTable from './screens/orderToTable';
import ApplyVouchers from './screens/applyVouchers';
import { getConfig } from './appConfig';
import { getSingleDeliveryOption } from './lib/utils';
import CreateAccount from './screens/createAccount';
import { ServiceCharge } from './screens/service-charge';
import PayTableBill from './screens/payTableBill';
import PayTableOverview from './screens/payTableOverview';
import SplitBill from './screens/splitBill';
import SplitBillByAmount from './screens/splitBillByAmount';
import SplitBillByItems from './screens/splitBillByItems';
import BillCompleted from './screens/billCompleted';

const Order = require('./screens/order' + (isWebConfig() ? 'Web' : '')).default;

const home = require('./assets/images/home-icon.svg');
const loyalty = require('./assets/images/loyalty-icon.svg');
const myAccount = require('./assets/images/myAccount-icon.svg');
const feedback = require('./assets/images/feedback-icon.svg');
const history = require('./assets/images/history-icon.svg');
const restaurants = require('./assets/images/location-icon.svg');
const social = require('./assets/images/social-icon.svg');
const login = require('./assets/images/login-icon.svg');
const logout = require('./assets/images/logout-icon.svg');
const settings = require('./assets/images/settings-icon.svg');
const referAFriend = require('./assets/images/refer-friend-icon.svg');
const vocuher = require('./assets/images/voucher-icon.svg');
const terms = require('./assets/images/terms.svg');
const privacy = require('./assets/images/privacy.svg');
const faq = require('./assets/images/faq.svg');
const menu = require('./assets/images/menu1.svg');

const { appType } = getConfig();
const { hasCampaignManager, hasOrdering, hasLoyalty } = appType;

const singleDeliveryOption = getSingleDeliveryOption();
const navConfig = {
  routes: [
    isWebConfig()
      ? []
      : {
          label: 'Home',
          path: '/dashboard',
          component: Dashboard,
          icon: home,
          exact: true,
          default: true,
        },
    {
      label: 'Menu',
      path: '/order',
      component: Order,
      icon: menu,
      exact: true,
      default: isWebConfig(),
    },
    ...(hasOrdering
      ? [
          {
            label: 'Start New Order',
            path: '/delivery-options',
            component: DeliveryOptions,
            icon: settings,
            protected: false,
            notInDrawer: !!singleDeliveryOption,
          },
        ]
      : []),
    ...(hasOrdering
      ? [
          {
            icon: settings,
            label: 'Pay Your Bill',
            path: '/table-bill-pay',
            component: PayTableBill,
            protected: false,
            notInDrawer: !localStorage.getItem('developer_debug_account'),
          },
        ]
      : []),

    ...(hasOrdering
      ? [
          {
            label: singleDeliveryOption ? singleDeliveryOption.label : '',
            path: '/click-and-collect',
            component: ClickAndCollect,
            icon: settings,
            protected: false,
            notInDrawer: !singleDeliveryOption,
          },
        ]
      : []),
    ...(hasLoyalty
      ? [
          {
            label: 'Loyalty',
            path: '/loyalty',
            component: Loyalty,
            icon: loyalty,
            protected: true,
            state: { tab: 'points' },
          },
        ]
      : []),
    ...(hasCampaignManager
      ? [
          {
            label: 'Vouchers',
            path: '/vouchers',
            component: Vouchers,
            icon: vocuher,
            protected: true,
          },
        ]
      : []),
    ...(hasCampaignManager
      ? [
          {
            label: 'Refer a Friend',
            path: '/refer-a-friend',
            component: ReferAFriend,
            icon: referAFriend,
            protected: true,
          },
        ]
      : []),
    { label: 'My Account', path: '/account', component: Account, icon: myAccount, protected: true },
    ...(hasOrdering
      ? [
          {
            label: 'Checkout',
            path: '/checkout',
            component: Checkout,
            icon: settings,
            notInDrawer: true,
            protected: false,
            default: false,
          },
        ]
      : []),
    ...(hasOrdering
      ? [
          {
            label: 'Apply Points',
            path: '/apply-points',
            component: ApplyPoints,
            icon: settings,
            notInDrawer: true,
            protected: true,
          },
        ]
      : []),
    ...(hasOrdering
      ? [
          {
            label: 'Service Charge',
            path: '/service-charge',
            component: ServiceCharge,
            notInDrawer: true,
          },
        ]
      : []),
    { label: 'History', path: '/history', component: History, icon: history, protected: true },
    {
      label: 'Leave Feedback',
      path: '/feedback',
      component: Feedback,
      icon: feedback,
      protected: true,
    },
    {
      label: 'Locations',
      path: '/locations',
      component: Locations,
      icon: restaurants,
      protected: false,
    },
    { label: 'Social Media', path: '/social', component: Social, icon: social, protected: false },
    {
      label: 'Contact Details',
      path: '/contact-details',
      component: ContactDetails,
      protected: !getConfig().general.hasGuestCheckout,
      notInDrawer: true,
    },
    {
      label: 'Apply Vouchers',
      path: '/apply-vouchers',
      component: ApplyVouchers,
      notInDrawer: true,
      protected: true,
    },
  ],
  authRoutes: [
    { label: 'Login', path: '/login', component: Login, icon: logout, fn: 'login' },
    { label: 'Logout', path: '/logout', icon: login, fn: 'logout' },
  ],
  additionalRoutes: [
    {
      label: 'Terms & Conditions',
      path: 'https://fathippo.co.uk/terms-of-use',
      exact: true,
      isLink: true,
    },
    {
      label: 'Privacy Policy',
      path: 'https://fathippo.co.uk/privacy-policy',
      exact: true,
      isLink: true,
    },
    {
      label: 'Faq & Support',
      path: 'faqs',
      isLink: true,
      externalLink: 'faqs',
    },
    {
      label: 'Allergens Info',
      path: 'allergens',
      isLink: true,
      externalLink: 'allergens',
    },
    {
      label: 'Secret Menu',
      path: 'https://fathippo.co.uk/secret-menu',
      exact: true,
      isLink: true,
    },
    {
      label: 'Apparel',
      path: 'https://fathippo.awesomedistro.com',
      exact: true,
      isLink: true,
    },
  ],
  notInMenuRoutes: [
    { path: '/register', component: Register },
    { path: '/create-account', component: CreateAccount },
    { path: '/reset-password', component: ResetPassword },
    ...(hasOrdering ? [{ path: '/item-details', component: ItemDetails }] : []),
    ...(hasOrdering ? [{ path: '/order', component: Order }] : []),
    ...(hasOrdering ? [{ path: '/cards', component: Cards }] : []),
    ...(hasOrdering ? [{ path: '/card-add', component: CardAdd }] : []),
    ...(hasOrdering ? [{ path: '/history-details', component: HistoryDetails }] : []),
    ...(hasOrdering ? [{ path: '/order-summary', component: OrderSummary }] : []),
    ...(hasOrdering ? [{ path: '/order-completed', component: OrderCompleted }] : []),
    ...(hasOrdering ? [{ path: '/delivery', component: Delivery }] : []),
    ...(hasOrdering ? [{ path: '/delivery-address-check', component: DeliveryAddressCheck }] : []),
    ...(hasOrdering ? [{ path: '/delivery-address-add', component: DeliveryAddressAdd }] : []),
    ...(hasOrdering ? [{ path: '/delivery-time', component: DeliveryTime }] : []),
    ...(hasOrdering ? [{ path: '/pick-up-point', component: PickUpPoint }] : []),
    ...(hasOrdering ? [{ path: '/pick-up-point-check', component: PickUpPointCheck }] : []),
    ...(hasOrdering ? [{ path: '/order-to-table', component: OrderToTable }] : []),
    ...(hasOrdering
      ? [{ path: '/table-overview', component: PayTableOverview, protected: false }]
      : []),
    ...(hasOrdering ? [{ path: '/split-bill', component: SplitBill, protected: false }] : []),
    ...(hasOrdering
      ? [{ path: '/split-bill-by-amount', component: SplitBillByAmount, protected: false }]
      : []),
    ...(hasOrdering
      ? [{ path: '/split-bill-by-items', component: SplitBillByItems, protected: false }]
      : []),
    ...(hasOrdering
      ? [{ path: '/bill-completed', component: BillCompleted, protected: false }]
      : []),
  ],
};

export default navConfig;
