import React from 'react';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { getRestaurants, setDeliveryOption } from '../../store/actions';
import { forwardTo, getSingleDeliveryOption } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import Basket from '../../lib/basket';
import api from '../../lib/api';
import { NormalText } from '../../components/common';
import './index.css';

class DeliveryOptions extends React.Component {
  setDeliveryOption = (delivery) => {
    this.props.dispatch(setDeliveryOption(delivery));
    Basket.setDeliveryOption(delivery);
    Basket.setOrderType(delivery.id);
    forwardTo(
      delivery.id === 'delivery'
        ? '/delivery'
        : delivery.id === 'charter-delivery'
        ? '/delivery'
        : delivery.id === 'pick-up-point'
        ? '/pick-up-point'
        : delivery.id === 'table'
        ? '/order-to-table'
        : '/click-and-collect',
    );
  };

  checkForSingleDeliveryOption = () => {
    const singleDeliveryOption = getSingleDeliveryOption();
    if (singleDeliveryOption) {
      this.setDeliveryOption(singleDeliveryOption);
    }
  };

  componentDidMount() {
    const { defaultMenuId } = this.props;
    if (this.props.restaurants.length === 0) this.props.dispatch(getRestaurants());
    Basket.reset();
    this.checkForSingleDeliveryOption();
    if (defaultMenuId) {
      api.getDefaultMenu(defaultMenuId).then((res) => {
        this.props.dispatch({
          type: 'SET_RESTAURANT_PROP',
          key: 'defaultMenu',
          value: { ...res, menuName: `${res.menuName} Default` },
          merge: true,
        });
      });
    }
  }

  componentDidUpdate() {
    this.checkForSingleDeliveryOption();
  }

  render() {
    const { __ } = this.props;
    return (
      <Layout hideSecondToolbar={true} color="transparent" noPadding={true}>
        <IonCard className="delivery-options-card">
          <IonCardHeader className="ion-text-center">
            <IonCardTitle>{__('Start New Order')}</IonCardTitle>
            <NormalText color="dark">{__('Select an order type to get started')}</NormalText>
          </IonCardHeader>

          <IonCardContent className="delivery-options-menu">
            <IonList lines="none">
              {getConfig().delivery.map((d, index) =>
                d.isRemoved ? null : (
                  <IonItem
                    key={'delivery-option-' + index}
                    color
                    disabled={d.isDisabled}
                    onClick={() => this.setDeliveryOption(d)}
                  >
                    <div tabIndex="-1"></div>
                    <IonLabel>{d.label}</IonLabel>
                  </IonItem>
                ),
              )}
            </IonList>
          </IonCardContent>
        </IonCard>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { restaurants } = state.restaurants;
  return {
    deliveryOption: state.orders.deliveryOption,
    defaultMenuId: state.common.defaultMenuId,
    restaurants: restaurants || [],
  };
};

export default connect(stateToProps)(withTranslation(DeliveryOptions));
