import { take, call, put, select, delay } from 'redux-saga/effects';
import api from '../../lib/api';
import { loading } from '../common/sagas';
import { forwardTo, isDefined, sortLocations, deepCopy, parseAllergenData } from '../../lib/utils';
import {
  SET_RESTAURANT_PROP,
  GET_RESTAURANTS,
  GET_REWARDS,
  GET_IKENTOO_MENU,
  GET_IKENTOO_MENUS_FOR_LOCATION,
  GET_RESTAURANTS_SNOOZED_DATA,
  SET_TABLE_DATA,
  GET_RESTAURANT_TABLE,
  GET_TABLE_BY_ID,
  SET_TABLE_NUMBERS,
} from './constants';
import { INIT_FIREBASE_LISTENER, LOCATION, SET_COMMON_MODAL } from '../common/constants';
import { /*STORE_ITEM_WEB,*/ SET_ORDERS_PROP } from '../orders/constants';
import { showToast } from '../actions';
import { translateSaga } from '../common/sagas';
import Basket from '../../lib/basket';
// import moment from '../../lib/moment'
import { getConfig } from '../../appConfig';
import { getMenusForSelectedTime } from '../../screens/clickAndCollect';
import { registerRequest } from '../../store/actions';
import { REGISTER_REQUEST } from '../constants';

export const getRestaurantsWorker = function* () {
  //get restaurants
  const restaurants = yield call(api.getRestaurants);
  let businessLocationIds = [];
  restaurants.map((restaurant) => {
    return businessLocationIds.push(restaurant.pos_location_id);
  });
  yield call(getTableNumbers, businessLocationIds);
  const store = yield select();
  const myLocation = store.common.myLocation;
  const sortRestaurants = yield call(sortLocations, restaurants, myLocation);

  yield put({ type: SET_RESTAURANT_PROP, key: 'restaurants', value: sortRestaurants });
  yield put({ type: LOCATION });
};

/* get Restaurants Saga */
export const getRestaurantsFlow = function* () {
  while (true) {
    yield take(GET_RESTAURANTS);
    //get restaurants
    yield call(loading, function* () {
      yield call(getRestaurantsWorker);
    });
  }
};

/* get Restaurants Saga */
export const getRewardsFlow = function* () {
  const { isReward } = getConfig().appType;
  while (true) {
    yield take(GET_REWARDS);
    //get restaurants
    yield call(loading, function* () {
      let rewards = null;
      if (isReward) {
        let result = yield call(api.getRewards);
        rewards = result.map((data) => {
          return {
            id: data.id,
            name: data.name,
            reward: data,
            stamps_required: data.stamps_required,
          };
        });
      } else {
        rewards = yield call(api.getRewards);
      }
      rewards = rewards.sort((a, b) => {
        if (a.stamps_required < b.stamps_required) {
          return -1;
        }
        if (a.stamps_required > b.stamps_required) {
          return 1;
        }
        return 0;
      });
      yield put({ type: SET_RESTAURANT_PROP, key: 'rewards', value: rewards });
    });
  }
};

const filterIkentooMenuItems = (items) => {
  let filteredItems = [];
  items.forEach((item) => {
    if (!item.menuEntry) {
      // break recursion when arrive to the product
      if (item.sku && Basket.isProductEnabled(item)) {
        filteredItems.push(item);
        return [item];
      } else {
        return [];
      }
    } else {
      const len = (item.menuEntry || []).length;
      if (len > 0) {
        const newFilteredItems = filterIkentooMenuItems(item.menuEntry);
        if (newFilteredItems.length > 0) {
          item.menuEntry = newFilteredItems;
          filteredItems.push(item);
        }
      }
    }
  });

  return filteredItems;
};

const filterMenu = (menu) => {
  if (menu && menu.menuEntryGroups) {
    menu.menuEntryGroups = filterIkentooMenuItems(menu.menuEntryGroups);
    return menu;
  }
  return menu;
};

/* get ikentoo menu Saga */
export const getIkentooMenuFlow = function* () {
  while (true) {
    const { menuId, businessLocationId, redirect } = yield take(GET_IKENTOO_MENU);
    const store = yield select();
    //get restaurants
    yield call(loading, function* () {
      let ikentooMenu = yield call(api.getIkenooMenu, menuId, businessLocationId);
      if (isDefined(ikentooMenu.error)) {
        yield put(showToast(yield call(translateSaga, 'Get restaurant menu error.'), 'warning'));
        ikentooMenu = {};
      }
      const categoryItems = ikentooMenu.menuEntry || ikentooMenu.menuEntryGroups || null;
      let items = Basket.flattenMenuItems(deepCopy(categoryItems));

      if (store.orders.storedItemWeb) {
        let foundItem = items.find((i) => i.sku === store.orders.storedItemWeb.item.sku);
        // let foundItem = items.find(i => i.sku === 'store.orders.storedItemWeb.item.sku')
        if (foundItem && Basket.isProductEnabled(foundItem)) {
          Basket.addToBasket(store.orders.storedItemWeb);
          let item = store.orders.storedItemWeb.item;
          let allergens = store.restaurants.allergens;
          let profile = store.profile.profile;
          let newArr = parseAllergenData(profile, item, allergens);
          let allergensCodes = item
            ? item.itemRichData
              ? (item.itemRichData.allergenCodes && item.itemRichData.allergenCodes.length) > 0
                ? item.itemRichData.allergenCodes
                : []
              : []
            : [];
          if (allergensCodes.length > 0) {
            let allergensData = [{ allergens: newArr }, { sku: item.sku }];
            Basket.setAllergen(allergensData);
          }
          // yield put({ type: STORE_ITEM_WEB, item: null })
          yield put({ type: SET_ORDERS_PROP, key: 'storedItemWeb', value: null });
        } else {
          yield put(
            showToast(yield call(translateSaga, 'Item not available for choosen menu'), 'warning'),
          );
        }
      }
      yield put({
        type: SET_RESTAURANT_PROP,
        key: 'ikentooMenu_original',
        value: deepCopy(ikentooMenu),
      });
      ikentooMenu = yield call(filterMenu, deepCopy(ikentooMenu));
      yield put({ type: SET_RESTAURANT_PROP, key: 'ikentooMenu', value: ikentooMenu });
      yield put({ type: SET_RESTAURANT_PROP, key: 'restaurantsUpdated', value: Date.now() });
      if (redirect) {
        // const deliveryOption = yield select(state => state.orders.deliveryOption)
        // yield call(forwardTo, deliveryOption && deliveryOption.id === 'delivery' ? '/delivery' : '/order')
        yield call(forwardTo, '/order');
        yield put({ type: SET_COMMON_MODAL, modal: 'isChooseMenuModalOpen', value: false });
      }
    });
  }
};

/* get ikentoo menus Saga */
export const getIkentooMenusForLocationFlow = function* () {
  while (true) {
    const { businessLocationId, additionalData, isDelivery } = yield take(
      GET_IKENTOO_MENUS_FOR_LOCATION,
    );
    //get restaurants
    yield call(loading, function* () {
      try {
        yield put({ type: SET_RESTAURANT_PROP, key: 'ikentooMenusForLocation', value: [] });
        let ikentooMenusForLocation = yield call(
          api.getIkentooMenusForLocation,
          businessLocationId,
        );
        if (isDefined(ikentooMenusForLocation.error)) {
          yield put(showToast(yield call(translateSaga, 'Get restaurant menus error.'), 'warning'));
          ikentooMenusForLocation = [];
        }
        // filter menus by picked time and redirect if it is necessary
        ikentooMenusForLocation = getMenusForSelectedTime(
          ikentooMenusForLocation,
          additionalData.pickTime,
          additionalData.json_time_selector,
        );
        if (Basket.getOrderType() === 'Table' && ikentooMenusForLocation.length === 0) {
          yield put(
            showToast(
              yield call(translateSaga, 'This service is currently unavailable!'),
              'warning',
            ),
          );
          return;
        }
        if (ikentooMenusForLocation.length === 1) {
          yield put({
            type: GET_IKENTOO_MENU,
            menuId: ikentooMenusForLocation[0].ikentooMenuId,
            businessLocationId,
            redirect: !isDelivery ? true : false,
          });
          Basket.setMenu(ikentooMenusForLocation[0].ikentooMenuId);
        } else {
          if (ikentooMenusForLocation.length === 0) {
            const store = yield select();
            const selectedRestaurant = store.restaurants.restaurants.find(
              (i) => i.business_location_id === businessLocationId,
            );
            if (selectedRestaurant) {
              yield put({
                type: GET_IKENTOO_MENU,
                menuId: selectedRestaurant.menu_id,
                businessLocationId,
                redirect: !isDelivery ? true : false,
              });
            }
          } else {
            if (!isDelivery) {
              yield put({ type: SET_COMMON_MODAL, modal: 'isChooseMenuModalOpen', value: true });
            }
          }
        }
        yield put({
          type: SET_RESTAURANT_PROP,
          key: 'ikentooMenusForLocation',
          value: ikentooMenusForLocation,
        });
      } catch (err) {
        // to do
      }
    });
  }
};

/* get ikentoo snoozed menu Saga */
export const getRestaurantSnoozeDataFlow = function* () {
  while (true) {
    yield take(GET_RESTAURANTS_SNOOZED_DATA);
    //get restaurants snoozed data
    const restaurantsSnoozedData = yield call(api.getRestaurantSnoozeData);
    yield call(getRestaurantsWorker);
    let restaurants = yield select((state) => state.restaurants.restaurants);
    restaurantsSnoozedData.forEach((snoozedRestaurant) => {
      restaurants = restaurants.map((restaurant) => {
        if (restaurant.business_location_id === snoozedRestaurant.business_location_id) {
          const newRestaurant = {
            ...restaurant,
            disabled_skus: snoozedRestaurant.data.disabled_skus,
            snoozed_skus: snoozedRestaurant.data.snoozed_skus,
          };
          if (
            Basket.getRestaurant() &&
            Basket.getRestaurant().business_location_id === snoozedRestaurant.business_location_id
          ) {
            Basket.setRestaurant(newRestaurant);
          }
          return newRestaurant;
        }
        return restaurant;
      });
    });
    // automatic get updated menu for seleced restaurant (disabled for now)
    // const restaurant = Basket.getRestaurant()
    // if (restaurant && restaurant.business_location_id) {
    // 	yield put({
    // 		type: GET_IKENTOO_MENUS_FOR_LOCATION,
    // 		businessLocationId: restaurant.business_location_id,
    // 		additionalData: {
    // 			pickTime: moment(Basket._collection_time).format('HH:mm'),
    // 			json_time_selector: restaurant.json_time_selector
    // 		}})
    // }
    yield put({ type: SET_RESTAURANT_PROP, key: 'restaurants', value: restaurants });
    let ikentooMenu = yield select((state) => state.restaurants.ikentooMenu_original);
    if (ikentooMenu) {
      ikentooMenu = yield call(filterMenu, deepCopy(ikentooMenu));
      yield put({ type: SET_RESTAURANT_PROP, key: 'defaultMenu', value: ikentooMenu });
      yield put({ type: SET_RESTAURANT_PROP, key: 'ikentooMenu', value: ikentooMenu });
      yield put({ type: SET_RESTAURANT_PROP, key: 'restaurantsUpdated', value: Date.now() });
    }
  }
};

const getTableNumbers = function* (bussinessLocationIds) {
  const tableNumbers = yield call(api.getFloorPlans, bussinessLocationIds);
  yield put({ type: SET_TABLE_NUMBERS, key: 'tableNumbers', value: tableNumbers });
};
export const getTableDataByIdFlow = function* () {
  while (true) {
    const { tableBillId } = yield take(GET_TABLE_BY_ID);
    let response = yield call(api.getTableById, tableBillId);
    yield put({
      type: SET_TABLE_DATA,
      key: 'tableData',
      value: JSON.parse(JSON.stringify(response)),
    });
    Basket.setBillPayData(response);
    if (response.total_due == 0 && window.location.pathname !== '/bill-completed') {
      yield call(forwardTo, '/table-bill-pay');
    }
    if (response.pay_method == 0) {
      Basket.setTablePaymentAmount(response.total_due);
      yield call(forwardTo, '/table-overview');
    } else if (
      response.pay_method == 1 &&
      window.location.pathname !== '/split-bill' &&
      window.location.pathname == '/table-overview'
    ) {
      yield call(forwardTo, '/split-bill');
      yield put(
        showToast(
          yield call(translateSaga, 'Payment method has been set by another member of table'),
          'warning',
        ),
      );
    } else if (
      response.pay_method == 2 &&
      (window.location.pathname == '/table-overview' ||
        window.location.pathname == '/split-bill' ||
        window.location.pathname == '/split-bill-by-items')
    ) {
      Basket.setTablePaymentAmount(response.total_due);
      yield call(forwardTo, '/split-bill-by-amount');
      yield put(
        showToast(
          yield call(translateSaga, 'Payment method has been set by another member of table'),
          'warning',
        ),
      );
    } else if (
      response.pay_method == 3 &&
      window.location.pathname == '/table-overview' &&
      window.location.pathname !== '/split-bill-by-items'
    ) {
      yield call(forwardTo, '/split-bill-by-items');
      yield put(
        showToast(
          yield call(translateSaga, 'Payment method has been set by another member of table'),
          'warning',
        ),
      );
    }
  }
};
export const setRestaurantTableFlow = function* () {
  while (true) {
    const { restaurant, table, is_guest } = yield take(GET_RESTAURANT_TABLE);
    yield call(loading, function* () {
      if (is_guest) {
        yield put({
          type: REGISTER_REQUEST,
          fromBillPay: true,
        });
      }

      let response = yield call(api.getTableData, table, restaurant.pos_location_id);

      if (response.bill_data && !response.billingCompleted) {
        yield put({
          type: SET_TABLE_DATA,
          key: 'tableData',
          value: JSON.parse(JSON.stringify(response)),
        });
        Basket.setBillPayData(response);

        yield put({
          type: INIT_FIREBASE_LISTENER,
          tableNumber: JSON.parse(JSON.stringify(response)).table_number,
          tableBillId: JSON.parse(JSON.stringify(response)).id,
        });

        if (response.pay_method == 0) {
          // const store = yield select();
          // let profile = store.profile.profile;

          // if (response.lead_user != profile.id) {
          //   yield call(forwardTo, '/table-opened');
          // } else {
          Basket.setTablePaymentAmount(response.total_due);
          yield call(forwardTo, '/table-overview');
          // }
        } else if (response.pay_method == 1) {
          yield call(forwardTo, '/split-bill');
        } else if (response.pay_method == 2) {
          Basket.setTablePaymentAmount(response.total_due);
          yield call(forwardTo, '/split-bill-by-amount');
        } else if (response.pay_method == 3) {
          yield call(forwardTo, '/split-bill-by-items');
        } else {
          yield call(forwardTo, '/table-overview');
        }

        // yield spawn(getTableDataPeriodicalSaga);
      } else {
        if (response.billingCompleted) {
          yield put(showToast(yield call(translateSaga, 'The bill already paid'), 'warning'));
        } else {
          yield put(showToast(yield call(translateSaga, 'Get table data error.'), 'warning'));
        }
      }
    });
  }
};

//get table data periodically
export const getTableDataPeriodicalSaga = function* () {
  while (true) {
    const table_name = Basket.getTableNumber();
    const restaurant = Basket.getRestaurant();
    if (table_name && restaurant) {
      let response = yield call(api.getTableData, table_name, restaurant.pos_location_id);
      if (response.bill_data && !response.billingCompleted) {
        yield put({ type: SET_TABLE_DATA, key: 'tableData', value: response });
      }
    }
    yield delay(30000);
  }
};
