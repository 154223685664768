import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import React, { Component } from 'react';
import Basket from '../../lib/basket.js';
import { withTranslation } from '../../lib/translate.js';
import { NormalText, StrongText } from '../common/index.js';
import { chevronDown, chevronUp } from 'ionicons/icons';

import './index.css';
import { connect } from 'react-redux';

class BillPayAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historyVisible: false,
    };
  }

  render() {
    const { __, isTableItemsOverviewVisible } = this.props;
    const { historyVisible } = this.state;
    return (
      <IonGrid
        className={`box-wrapper space-between ${
          Basket.bill_data.total_paid > 0 ? 'box-wrapper-list' : ''
        } ${isTableItemsOverviewVisible ? 'items-overwiew-opened' : ''}`}
      >
        <IonRow>
          <IonCol>{__('Bill Total')}</IonCol>
          <IonCol className="righted">{Basket.getBillTotal()}</IonCol>
        </IonRow>
        {Basket.bill_data.total_paid > 0 && (
          <>
            <IonRow
              className="payment-history-header pointer"
              onClick={() => this.setState({ historyVisible: !historyVisible })}
            >
              <IonCol>
                <div>
                  {__('Already Paid')}
                  <IonIcon icon={historyVisible ? chevronUp : chevronDown}></IonIcon>
                </div>
              </IonCol>
              <IonCol className="righted">{!historyVisible && Basket.getPaidAmount()}</IonCol>
              {historyVisible && (
                <>
                  {Basket.getPayEvents()
                    .filter((el) => el.status == 'ok')
                    .map((el) => (
                      <IonRow className="payment-history-wrapper" style={{ width: '100%' }}>
                        <IonCol size="8">
                          <NormalText>{el.first_name}</NormalText>
                        </IonCol>
                        <IonCol size="4" className="righted">
                          <NormalText>{Basket.formatPrice(el.payment_amount / 100)}</NormalText>
                        </IonCol>
                      </IonRow>
                    ))}
                </>
              )}
            </IonRow>

            <IonRow>
              <IonCol>{__('Amount Left To Pay')}</IonCol>
              <IonCol className="righted">{Basket.getAmountLeftToPay()}</IonCol>
            </IonRow>
          </>
        )}
      </IonGrid>
    );
  }
}
const stateToProps = (state) => {
  const { isTableItemsOverviewVisible } = state.common;
  const { tableData } = state.restaurants;
  return {
    isTableItemsOverviewVisible,
    tableData,
  };
};
export default connect(stateToProps)(withTranslation(BillPayAmount));
