import React from 'react';
import { IonButton, IonInput, IonToggle, IonItem, IonLabel, IonList, IonAlert } from '@ionic/react';
import { connect } from 'react-redux';
import Layout from '../../components/layout';
import PasswordInput from '../../components/passwordInput';
import { forwardTo, getDefaultRoute } from '../../lib/utils';
import { beforeShowTimePicker, beforeCloseTimePicker } from '../../store/actions';
import { registerRequest, setModal, setSysLocale, setRegisterForm } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import { FieldError, Title, SmallText, Spacer } from '../../components/common';
import { validateForm } from '../../lib/utils';
import Mobiscroll from '../../components/mobiscroll';
import Loading from '../../components/spinner';
import { Plugins } from '@capacitor/core';
import './index.css';

const { Device } = Plugins;
const { DatePicker } = Mobiscroll;

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.navToTerms = this.navToTerms.bind(this);
    this.navToPolicy = this.navToPolicy.bind(this);
    this.formConfig = {
      first_name: { type: 'text', required: false },
      last_name: { type: 'text', required: false },
      mobile: { type: 'text', required: false },
      birthday: { type: 'birthday', required: false },
      email: { type: 'email', required: true },
      password: { type: 'password', required: true },
      accepted_terms_and_conditions: { type: 'toggle', toggle: true },
    };
  }

  handleInput(key, val) {
    const { dispatch } = this.props;
    dispatch(setRegisterForm(key, val));
    if (key === 'accepted_terms_and_conditions') {
      let formErrors = { ...this.state.formErrors };
      formErrors.accepted_terms_and_conditions = undefined;
      this.setState({ formErrors });
    }
  }

  handleRegister() {
    this.handleInput('guest', false);
    const { registerFormData } = this.props;
    let formErrors = validateForm(this.formConfig, registerFormData);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      this.props.dispatch(registerRequest());
    }
  }

  componentDidMount() {
    const { loggedIn } = this.props.auth;
    Device.getLanguageCode().then((res) => {
      const sysLocale = res.value.substr(0, 2);
      if (sysLocale) {
        this.props.dispatch(setSysLocale(sysLocale));
      }
    });
    if (loggedIn) {
      const defaultRoute = getDefaultRoute();
      forwardTo(defaultRoute.path);
    }
  }

  handlePeriodicalSaga = (flag) => {
    const { dispatch } = this.props;
    dispatch({ type: 'SET_COMMON_PROP', key: 'emitterFlag', value: flag });
  };

  returnToLogin = (history) => history.goBack();

  navToTerms = () => window.open('https://fathippo.co.uk/terms-of-use', '_blank');

  navToPolicy = () => window.open('https://fathippo.co.uk/privacy-policy', '_blank');

  render() {
    const { __, isRegisterModalOpen, registerFormData } = this.props;
    const email = registerFormData.email;
    const password = registerFormData.password;
    const first_name = registerFormData.first_name;
    const last_name = registerFormData.last_name;
    const mobile = registerFormData.mobile;
    const birthday = registerFormData.birthday;
    const accepted_terms_and_conditions = registerFormData.accepted_terms_and_conditions;
    const is_subscribed = registerFormData.is_subscribed;
    // const newClass = isShowTimePicker ? 'red' : 'gray'
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

    return (
      <Loading transparent>
        <Layout color headerTitle="Register" hideSecondToolbar={true}>
          <Title>{__('Create an Account')}</Title>
          <SmallText className="heading">
            {__('To register your account, please enter your email address and a password')}
          </SmallText>
          <Spacer />
          <IonList>
            <IonItem>
              <IonInput
                placeholder={__('Enter your email address') + ' *'}
                onIonChange={(e) => this.handleInput('email', e.target.value)}
                required={true}
                type="email"
                pattern="email"
                inputmode="email"
                value={email}
              ></IonInput>
            </IonItem>
            {this.state.formErrors.email ? (
              <FieldError className="field-error" value={__(this.state.formErrors.email)} />
            ) : null}
            <IonItem>
              <PasswordInput
                placeholder={__('Enter your password') + ' *'}
                onIonChange={(e) => this.handleInput('password', e.target.value)}
                value={password}
              />
            </IonItem>
            {this.state.formErrors.password ? (
              <FieldError className="field-error" value={__(this.state.formErrors.password)} />
            ) : null}
            <IonItem>
              <IonInput
                placeholder={__('Enter your first name (optional)')}
                onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                required={false}
                type="text"
                pattern="text"
                inputmode="text"
                value={first_name}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                placeholder={__('Enter your last name (optional)')}
                onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                required={false}
                type="text"
                pattern="text"
                inputmode="text"
                value={last_name}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonInput
                placeholder={__('Enter your mobile number (optional)')}
                onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                required={false}
                type="tel"
                pattern="tel"
                inputmode="tel"
                value={mobile}
              ></IonInput>
            </IonItem>
            <IonItem>
              {/* <label className={`date-picker-label date-picker-label--${newClass}`} htmlFor="demo-non-form">{ __('Date Birth') }</label> */}
              <DatePicker
                className="data-picker-input"
                placeholder="Enter your date of birth (optional)"
                display="bottom"
                max={yesterday}
                setText={__('Done')}
                cancelText={__('Cancel')}
                value={birthday}
                onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                dateFormat="dd/mm/yy"
                // onBeforeShow={ () => { this.props.dispatch(beforeShowTimePicker()) }}
                onClose={() => {
                  this.props.dispatch(beforeCloseTimePicker());
                  this.handlePeriodicalSaga(true);
                }}
                onShow={() => this.handlePeriodicalSaga(false)}
              />
            </IonItem>
          </IonList>
          <Spacer />
          <div className="box-holder box-holder--register no-padding">
            <IonList>
              <IonItem>
                <div tabIndex="-1"></div>
                <div className="toggle padded-small no-padding-right">
                  <IonLabel>
                    <h2 className="uppercase">{__('Accept T&Cs')}</h2>
                    <IonLabel className="ion-text-wrap">
                      <SmallText>
                        {__('By signing up you agree to our')}{' '}
                        <span className="link underlined" onClick={this.navToTerms}>
                          {__('terms and conditions')}
                        </span>{' '}
                        {__('and')}{' '}
                        <span className="link underlined" onClick={this.navToPolicy}>
                          {__('privacy policy')}
                        </span>
                      </SmallText>
                    </IonLabel>
                  </IonLabel>
                  <FieldError
                    className="field-error"
                    value={__(this.state.formErrors.accepted_terms_and_conditions)}
                  />
                </div>
                <IonToggle
                  style={{ paddingRight: '16px' }}
                  color="secondary"
                  slot="end"
                  checked={accepted_terms_and_conditions}
                  onIonChange={(e) =>
                    this.handleInput('accepted_terms_and_conditions', e.detail.checked)
                  }
                />
              </IonItem>
              <IonItem lines="none">
                <div tabIndex="-1"></div>
                <div className="padded-small no-padding-right">
                  <IonLabel>
                    <h2 className="uppercase">{__('Email Opt in')}</h2>
                    <IonLabel className="ion-text-wrap">
                      <SmallText>
                        {__(
                          "I'd like to receive email updates that contain news, offers and promotions",
                        )}
                      </SmallText>
                    </IonLabel>
                  </IonLabel>
                </div>
                <IonToggle
                  style={{ paddingRight: '16px' }}
                  color="secondary"
                  slot="end"
                  checked={is_subscribed}
                  onIonChange={(e) => this.handleInput('is_subscribed', e.detail.checked)}
                />
              </IonItem>
            </IonList>
          </div>
          <Spacer />
          <div className="top-medium">
            <IonButton
              expand="block"
              fill="solid"
              color
              className="register-button customized-button"
              onClick={() => this.handleRegister()}
            >
              {__('Register')}
            </IonButton>
          </div>
        </Layout>
        <IonAlert
          isOpen={isRegisterModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isRegisterModalOpen', false))}
          header={__('Success')}
          message={__('Register processed. Please check your mail')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false))),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isRegisterModalOpen, registerFormData } = state.profile;
  const { isShowTimePicker } = state.restaurants;
  return {
    auth,
    isRegisterModalOpen,
    registerFormData,
    isShowTimePicker,
  };
};

export default connect(stateToProps)(withTranslation(Register));
