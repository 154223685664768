import React from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonButton,
} from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import { withRouter } from 'react-router';
// import { arrowForward } from 'ionicons/icons'
import { SmallText } from '../../components/common';
import NoData from '../../components/noData';
import Basket from '../../lib/basket';
import earnedArrow from '../../assets/images/earned-arrow.svg';
import redeemedArrow from '../../assets/images/redeemed-arrow.svg';
import { getConfig } from '../../appConfig';
import NoDataSecond from '../../components/noDataSecond';
import noDataImage from '../../assets/images/logo-main44.png';
import { forwardTo } from '../../lib/utils';

const doRefresh = (event, getTransactionHistory) => {
  setTimeout(() => {
    event.detail.complete();
    getTransactionHistory();
  }, 1000);
};

const HistoryTab = withRouter(
  ({ __, transactionHistory, getTransactionHistory, history, ...rest }) => {
    const type = rest.type || 'order';

    const handleRowClick = (item) => {
      if (type === 'order') {
        history.push('./history-details', { order: item });
      } else {
        // history.push('./history-details')
      }
    };
    const orderAgain = (past_orders, option) => {
      Basket.reset();
      Basket.setDeliveryOption(option);
      forwardTo(option.route, { selectedRestaurant: past_orders });
    };

    return (
      <>
        <IonRefresher slot="fixed" onIonRefresh={(e) => doRefresh(e, getTransactionHistory)}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className="history-content">
          {(transactionHistory || []).length === 0 ? (
            type === 'order' ? (
              <NoDataSecond label="Your order History will appear here" noDataImage={noDataImage} />
            ) : (
              <NoDataSecond
                label="Your loyalty history will appear here"
                noDataImage={noDataImage}
              />
            )
          ) : (
            <IonGrid>
              {transactionHistory.map((i, index) => {
                const { stamp_power, transaction_date, location_name, label, item } = i;
                // const splitedLabel = label.split('#').splice(1).join('')
                const labelLocationName = label.split('#').splice(0).join('');
                let orderLabel = Basket.getOrderType(i.item);
                let option = (getConfig().delivery || []).find((d) => d.id === orderLabel);
                let orderId = i && i.item ? ' #' + i.item.id : '';
                return (
                  <IonRow key={index} className="history-item">
                    <IonCol
                      onClick={() => handleRowClick(item)}
                      className="history-details-description"
                    >
                      {type === 'order' ? (
                        <div className="order-button-wrapper">
                          <div className="order-button bordered-primary">
                            {__(item && item.status ? item.status : '')}
                          </div>
                        </div>
                      ) : null}

                      <SmallText
                        color="primary"
                        className={`${type === 'order' && 'order-location-name'}`}
                      >
                        {type === 'order' ? labelLocationName : location_name}
                      </SmallText>
                      <h2>
                        {type === 'order' ? (option ? option.label + orderId : '') : __(label)}
                      </h2>
                      <SmallText color="primary">
                        <span className="capitalize">
                          {type === 'order' ? option && __(`${option.id}:`) : ''}
                        </span>{' '}
                        {Basket.getDate(transaction_date).format('DD MMMM YYYY [at] h:mm a')}
                      </SmallText>
                    </IonCol>
                    {type === 'order' ? (
                      <IonCol style={{ flex: '0 1 0', alignSelf: 'center' }}>
                        <IonButton
                          className="customized-button order-again-button"
                          onClick={() => orderAgain(item, option)}
                          color="secondary"
                        >
                          {__('Order Again')}
                        </IonButton>
                      </IonCol>
                    ) : (
                      <IonCol style={{ alignSelf: 'center' }}>
                        <p className={stamp_power < 0 ? 'redeem' : ''}>
                          <img
                            src={stamp_power < 0 ? redeemedArrow : earnedArrow}
                            alt="point-arrows"
                          />
                          {stamp_power < 0 ? '' : '+'}
                          {stamp_power} {__('points')}
                        </p>
                      </IonCol>
                    )}
                  </IonRow>
                );
              })}
            </IonGrid>
          )}
        </div>
      </>
    );
  },
);

export default withTranslation(HistoryTab);
