import React from 'react';
import { IonTitle, IonToolbar, IonButtons } from '@ionic/react';
import BackButton from '../backButton';
import './index.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from '../../lib/translate';

const Header = ({ title, hideBack, backHandler, is_guest }) => {
  const guest = is_guest ? 'guest' : '';
  return (
    <IonToolbar className={`dynamic-header header-button-${guest}`}>
      {!hideBack ? (
        <IonButtons slot="start">
          <BackButton backHandler={backHandler} />
        </IonButtons>
      ) : null}
      <IonTitle className="ion-text-center" style={{ visibility: 'hidden' }}>
        {title}
      </IonTitle>
      {!hideBack ? (
        <IonButtons slot="end">
          <BackButton style={{ visibility: 'hidden' }} backHandler={backHandler} />
        </IonButtons>
      ) : null}
    </IonToolbar>
  );
};

const stateToProps = (store) => {
  return {
    is_guest: store.profile.profile.is_guest,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Header)));
