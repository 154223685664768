import React from 'react';
import { withRouter } from 'react-router';
import { IonButton, IonIcon, IonItem, IonLabel, IonInput } from '@ionic/react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Title, SmallText, Spacer, Sectiontitle, NormalText } from '../../components/common';
import Basket from '../../lib/basket';
//import { makeKey } from '../../lib/utils'
import ValidateButton from '../../components/validateButton';
import { pencilOutline } from 'ionicons/icons';
import { getConfig } from '../../appConfig';
import Modal from '../../components/modal';
import { ApplyPoints } from '../../screens/applyPoints';
import Incrementer from '../../components/incrementer';
// import moment from '../../lib/moment'
import { PillGroup } from '../../components/pill';
import { connect } from 'react-redux';
import OrderContent from '../../components/orderContent';
import {
  forwardTo,
  validateProfileData,
  goBack,
  go,
  /*isEmptyObject,*/ isWebConfig,
  isEmptyObject,
} from '../../lib/utils';
import { setModal, setProtectedReferrer, restoreAuth } from '../../store/actions';
// import redeemIcon from '../../assets/images/reedem-point-icon.svg'
import ContentHeader from '../../components/contentHeader';
import './index.css';

const {
  changeItemQuantity,
  itemsCount,
  getItems,
  setMobile,
  getMobile,
  getAllergen,
  getTotal,
  isMinimumOrderTotalSatisfied,
  getPickUpPoint,
  getDeliveryAddress,
  getDeliveryOption,
  getTableNumber,
} = Basket;

class OrderSummaryRaw extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    this.state = {
      quantityModal: null,
      allergenModalOpen: false,
      applyPointsModalOpen: false,
      mobile: getMobile() || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({ mobile: this.props.profile.mobile });
    }
  }

  handleInput = (key, val) => {
    this.setState({ [key]: val });
    setMobile(val);
  };

  handleSubmit = () => {
    const { cards } = this.props;
    if (!isMinimumOrderTotalSatisfied()) {
      // display toast with flag 'true'
      isMinimumOrderTotalSatisfied(true);
      return;
    }
    if (getTotal() === 0) {
      Basket.createOrder();
      return;
    }
    if (cards && cards.length >= 1) {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        forwardTo('/checkout');
        setMobile(this.props.profile.mobile);
      } else {
        forwardTo('/contact-details');
      }
    } else {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        setMobile(this.props.profile.mobile);
        forwardTo('/checkout');
      } else {
        forwardTo('/contact-details');
      }
    }
  };

  handleOrderItemClick = (item, index) => {
    this.setState({ quantityModal: { item, index } });
  };

  updateItemQuantity = () => {
    const { item, index } = this.state.quantityModal;
    if (this.state.quantityModal) {
      changeItemQuantity(index, item.quantity);
      this.setState({ quantityModal: null });
    }
  };

  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantityModal: {
        ...this.state.quantityModal,
        item: {
          ...this.state.quantityModal,
          quantity: newQuantity,
        },
      },
    });
  };

  handleApplyModal = (flag) => {
    const { history, auth } = this.props;
    const isAuth = auth.loggedIn;
    if (!isAuth) {
      this.props.dispatch(setProtectedReferrer(history.location.path));
      this.props.dispatch(restoreAuth());
      forwardTo('/login');
    } else {
      if (isWebConfig()) {
        this.setState({ applyPointsModalOpen: flag });
      } else {
        forwardTo('/apply-points');
      }
    }
  };
  changeTableNumber = (tableNumeber) => {
    Basket.setTableNumber(tableNumeber);
  };

  render() {
    const { __, profile, auth, dispatch } = this.props;
    const { quantityModal, allergenModalOpen, applyPointsModalOpen, mobile } = this.state;
    const valid = validateProfileData(profile).isValid;
    const allergens = getAllergen() || [];
    const contactLabelArrg = isWebConfig() ? { position: 'floating' } : { slot: 'start' };
    const isAuth = auth.loggedIn;
    const labelTitle =
      Basket.getOrderType() === 'Click & Collect' ? 'Click & Collect Order' : Basket.getOrderType();
    const appliedVouchers = Basket.getAppliedVocuher();
    return (
      <>
        <div className="absolute-content flex-row-wrapper">
          <div className="scrollable-y checkout">
            {Basket.getItems().length > 0 ? (
              <ContentHeader
                __={__}
                deliveryOption={getDeliveryOption()}
                orderType={labelTitle}
                deliveryAddress={getDeliveryAddress()}
                pickUpPoint={getPickUpPoint()}
                changeTableNumber={this.changeTableNumber}
              />
            ) : null}
            <IonItem className="contact-number" lines="full">
              <div tabIndex="-1"></div>
              <IonLabel {...contactLabelArrg} className="ion-text-wrap contact-number-label">
                <Sectiontitle className="no-margin">{__('Contact number')}</Sectiontitle>
              </IonLabel>
              <IonInput
                className="mobile-field"
                onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                clearInput
                required={true}
                type="tel"
                pattern="tel"
                inputmode="tel"
                value={mobile}
              >
                {mobile === '' ? (
                  // <IonButtons slot='end' className='no-margin'>
                  // 	<IonButton color="gray" /*disabled={ value === '' }*/ fill="clear" size="small" /*onTouchStart={() => this.togglePass(true)} onTouchEnd={() => this.togglePass(false)} onMouseDown={() => this.togglePass(true)} onMouseUp={() => this.togglePass(false)}*/>
                  // 	</IonButton>
                  // </IonButtons>
                  <IonIcon icon={pencilOutline} className="contact-number-icon" />
                ) : null}
              </IonInput>
            </IonItem>
            <Spacer />
            <NormalText className="bold">{__('Items Ordered')}</NormalText>
            {itemsCount() > 0 ? (
              <OrderContent
                showAddItems={true}
                handleOrderItemClick={this.handleOrderItemClick.bind(this)}
                handleApplyModal={this.handleApplyModal}
              />
            ) : (
              <IonItem lines="none">
                <div
                  tabIndex="-1"
                  className="sectiontitle"
                  style={{ width: '100vh', textAlign: 'center' }}
                >
                  {__('No items')}
                </div>
              </IonItem>
            )}
            <div className="validate-content">
              <ValidateButton verifiedColor="secondary" />
              {isAuth && !valid && getConfig().appType.hasEmailValidationEnabled ? (
                <div className="verified-content">
                  <SmallText color="grey">
                    {__('You can earn, but not redeem points until your account is verified')}
                  </SmallText>
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex-min">
            {allergens.length > 0 ? (
              <IonButton
                fill="clear"
                expand="block"
                className="link underlined"
                color="secondary"
                onClick={() => this.setState({ allergenModalOpen: true })}
              >
                {__('View allergen information')}
              </IonButton>
            ) : null}
            {getConfig().appType.hasVouchers ? (
              <IonButton
                className={`redeem-points-btn ${
                  itemsCount() === 0 || !valid ? 'disabled' : ''
                } customized-button--dark`}
                onClick={() => {
                  if ((itemsCount() === 0 || !valid) && isAuth) {
                    dispatch(setModal('isVerfiedModalOpen', true));
                  } else {
                    forwardTo('/apply-vouchers');
                  }
                }}
                expand="block"
                fill="outline"
              >
                {appliedVouchers && appliedVouchers.length > 0
                  ? __('Replace Voucher')
                  : __('Apply Vouchers')}
              </IonButton>
            ) : null}
            {!getConfig().appType.isReward ? (
              <IonButton
                expand="block"
                fill="outline"
                className={`redeem-points-btn ${
                  itemsCount() === 0 || !valid ? 'disabled' : ''
                } customized-button--gray`}
                onClick={() => {
                  if ((itemsCount() === 0 || !valid) && isAuth) {
                    dispatch(setModal('isVerfiedModalOpen', true));
                  } else {
                    this.handleApplyModal(true);
                  }
                }}
              >
                {__('Redeem Loyalty')}
              </IonButton>
            ) : null}
            {isEmptyObject(this.props.profile) && getConfig().general.hasGuestCheckout ? (
              <IonButton
                expand="block"
                fill="outline"
                className={`customized-button--gray`}
                onClick={() => {
                  forwardTo('/login');
                }}
              >
                {__('Already registered? Login')}
              </IonButton>
            ) : null}
            <IonButton
              disabled={itemsCount() === 0}
              onClick={this.handleSubmit}
              expand="block"
              className={
                'checkout-btn ' +
                (!isMinimumOrderTotalSatisfied() ? 'greyed' : '') +
                ' customized-button'
              }
            >
              {getTableNumber() ? `${__('Checkout on table')} ${getTableNumber()}` : __('Checkout')}
            </IonButton>
          </div>
        </div>

        <Modal
          cssClass="quantity-modal"
          title={__('Change quantity')}
          action={this.updateItemQuantity}
          actionLabel={__('Change')}
          isOpen={!!quantityModal}
          onDidDismiss={() => this.setState({ quantityModal: null })}
        >
          {quantityModal && quantityModal.item ? (
            <Incrementer
              allowNegative={false}
              quantity={quantityModal.item.quantity}
              onUpdate={this.onIncrementerUpdate}
            />
          ) : null}
        </Modal>

        <Modal
          cssClass="allergen-modal"
          isOpen={allergenModalOpen}
          onDidDismiss={() => this.setState({ allergenModalOpen: false })}
        >
          <div
            style={{ width: '100%', height: '100%' }}
            className="absolute-content flex-row-wrapper"
          >
            <div className="scrollable-y rhino">
              <Title className="centered">{__('Allergen Information')}</Title>
              <Spacer />
              {getItems().map((item, index) => {
                let data = allergens.find((allergen) => allergen[1].sku === item.item.sku);
                if (data && data.length > 0) {
                  return (
                    <div key={'allergen-' + index}>
                      <Sectiontitle>
                        {item.quantity} x {item.item.productName}
                      </Sectiontitle>
                      <PillGroup
                        items={data[0].allergens}
                        borderColor="primary"
                        color="primary"
                        headerTitle="Order Summary"
                        paddingTop={0}
                      />
                      <Spacer />
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div className="flex-min">
              <Spacer />
              {/* <IonButton fill="clear" className="link underlined" color="dark" onClick={() => this.setState({ allergenModalOpen: false })}>{ __('Hide allergen information')}</IonButton> */}
              <IonButton
                expand="block"
                color
                className="customized-button"
                onClick={() => this.setState({ allergenModalOpen: false })}
              >
                {__('Close')}
              </IonButton>
            </div>
          </div>
        </Modal>
        <Modal
          cssClass="apply-points-modal"
          isOpen={applyPointsModalOpen}
          onDidDismiss={() => this.handleApplyModal(false)}
        >
          <ApplyPoints
            handleApplyModal={this.handleApplyModal}
            applyPointsModalOpen={applyPointsModalOpen}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { basketUpdated, deliveryOption } = store.orders;
  const { auth } = store.profile;
  return {
    basketUpdated,
    deliveryOption,
    profile: store.profile.profile,
    cards: store.orders.cards || [],
    auth,
  };
};

export const OrderSummary = connect(mapStateToProps)(withRouter(withTranslation(OrderSummaryRaw)));

class OrderSummaryWrapped extends React.Component {
  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromItemDetails
    ) {
      // skip item details page when we going back from order summary
      go(-2);
    } else if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipBackToThePreviousPage
    ) {
      forwardTo('/order');
    } else {
      goBack();
    }
  };
  render() {
    const { __ } = this.props;
    return (
      <Loading>
        <Layout
          color
          headerTitle={__('Order Summary')}
          backHandler={this.backHandler}
          scrollY={false}
        >
          <OrderSummary />
        </Layout>
      </Loading>
    );
  }
}

export default withTranslation(OrderSummaryWrapped);
