import React from 'react';
import {
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonButton,
  isPlatform,
} from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { Title, SmallText, Spacer, Sectiontitle, NormalText } from '../../components/common';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import { createOrder, setPaymentToken, showToast } from '../../store/actions';
import { forwardTo, insertIntoArray, goBack, isEmptyObject, isDefined } from '../../lib/utils';
import { Capacitor, Plugins } from '@capacitor/core';
import Stripe from '../../lib/stripe';
import { getConfig } from '../../appConfig';
import './index.css';
import Modal from '../../components/modal';
import { checkSnoozedTimes, formatDataForTime } from '../clickAndCollect';
import moment from '../../lib/moment';
import Mobiscroll from '../../components/mobiscroll';
import { setCommonModal } from '../../store/actions';
import DrawPayButton from '../../components/drawPayButton';
import CheckoutPay from './CheckoutPay';
import { logout } from '../../store/actions';

const { Device } = Plugins;
const { SelectOption } = Mobiscroll;
const { getMobile, setMobile } = Basket;
const isWeb = () => Capacitor.platform === 'web';

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    this.state = {
      selectedCard: null,
      selectedJudopayCard: null,
      appDeviceVersion: '',
    };
  }
  async componentDidMount() {
    const info = await Device.getInfo();
    const { google } = window;
    const appDeviceVersion = info.appVersion;
    this.setState({ appDeviceVersion });
    console.log('APP DEVICE VERSION', appDeviceVersion);

    const { profile } = this.props;
    if (getConfig().payment === 'judopay') {
      let judoPayCardsLen = profile.judo_pay_cards.length - 1;
      this.changeSelectedPaymentCard(judoPayCardsLen);
    }
    if (profile.cardToken) {
      this.changeSelectedPaymentCard(profile.cardToken);
    }
    if (google) {
      window.googlePayClient = new google.payments.api.PaymentsClient({
        environment: 'PRODUCTION',
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { profile } = this.props;
    if (prevProps.profile.cardToken !== profile.cardToken) {
      this.changeSelectedPaymentCard(profile.cardToken);
    }
    if (
      getConfig().payment === 'judopay' &&
      prevProps.profile.judo_pay_cards.length !== profile.judo_pay_cards.length
    ) {
      let judoPayCardsLen = profile.judo_pay_cards.length - 1;
      this.changeSelectedPaymentCard(judoPayCardsLen);
    }
  }

  createOrderForPaymentType = (paymentType) => {
    if (getConfig().payment === 'judopay') {
      this.props.dispatch(setPaymentToken(this.props.cards[this.state.selectedCard].cardToken));
      this.props.dispatch(createOrder(paymentType));
    } else {
      //stripe
      this.props.dispatch(createOrder(paymentType));
    }
  };

  createOrder = (paymentType) => {
    if (Capacitor.platform !== 'web') {
      console.log(this.state.appDeviceVersion, 'NOT WEB');
      Basket.setAppVersion(this.state.appDeviceVersion);
    }

    if (
      !isEmptyObject(Basket.getDeliveryOption()) &&
      Basket.getDeliveryOption().id === 'delivery'
    ) {
      const minOrder = Basket.getMinOrder();
      const total = Basket.getSubTotal();
      if (minOrder <= total) {
        this.createOrderForPaymentType(paymentType);
      } else {
        this.props.dispatch(
          showToast('Minimum order must be ' + Basket.formatPrice(minOrder), 'warning'),
        );
      }
    } else {
      this.createOrderForPaymentType(paymentType);
    }
  };

  drawPayButton = () => {
    const { __ } = this.props;
    let ret = null;
    if (Stripe.getStripeInstance() && Capacitor.platform !== 'web') {
      if (!isPlatform('ios')) {
        //android
        ret = (
          <IonButton expand="block" color="primary" onClick={() => this.createOrder('google')}>
            {__('Google Pay')}
          </IonButton>
        );
      } else {
        //ios
        ret = (
          <IonButton expand="block" color="primary" onClick={() => this.createOrder('apple')}>
            {__('Apple Pay')}
          </IonButton>
        );
      }
    }
    return ret;
  };

  changeSelectedPaymentCard = (cardId) =>
    this.setState({ selectedCard: cardId }, () => {
      Basket.changeSelectedCard(cardId);
      // this.props.dispatch(updateProfile({ cardToken: cardId }, true)) // reason: this line has commented, because we're updating user profile on the BO
    });

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipContactDetails
    ) {
      forwardTo('/order', { skipBackToThePreviousPage: true });
    } else if (this.props.location?.state?.fromTablePay) {
      goBack();
    } else if (this.props.location.pathname === '/checkout') {
      forwardTo('/order', { skipBackToThePreviousPage: true });
    } else {
      goBack();
    }
  };

  showCollectedModal = (modalOpen) => {
    this.setState({ collectedModal: modalOpen });
  };

  showDriverModal = (modalOpen) => {
    this.setState({ driverModal: modalOpen });
  };

  changeTime = (selectedTime, minDT) => {
    let h = parseInt(selectedTime.split(':')[0]);
    let m = parseInt(selectedTime.split(':')[1]);
    const formattedDT = moment(minDT).hours(h).minutes(m);
    Basket.setCollectionTime(formattedDT);
  };

  setPickTime = (inst, minDT) => {
    if (inst.getVal()) {
      this.changeTime(inst.getVal(), minDT);
      this.setState({ pickTime: inst.getVal() });
    }
  };

  render() {
    const {
      __,
      cards,
      dispatch,
      deliveryTimeModalOpen,
      restaurants,
      clientSecret,
      profile,
      location,
    } = this.props;
    const { pickTime } = this.state;
    const store = restaurants.find((restaurant) => restaurant.id === Basket.getRestaurant()?.id);
    let snoozedTimes = [];
    if (Basket.getDeliveryOption().id !== 'table')
      snoozedTimes = checkSnoozedTimes(store, Basket.getDeliveryOption().id);
    let minDT = moment();
    if (store && isDefined(store.order_slot_lead_time)) {
      minDT.add(store.order_slot_lead_time, 'minutes');
    }
    const timePickerOptions = formatDataForTime(
      Basket.getRestaurant(),
      minDT,
      Basket.getRestaurant()?.id,
      Basket.getDeliveryOption().id === 'charter-delivery' ? true : false,
      false,
      snoozedTimes,
    );
    return (
      <>
        <Loading transparent>
          <Layout color headerTitle={__('Checkout')} backHandler={this.backHandler}>
            <div className="flex-row-wrapper absolute-content">
              <Title>{__('Checkout')}</Title>
              <SmallText color="primary" className="heading thiner-text">
                {__('Confirm the payment method to use to complete your order')}
              </SmallText>
              <Spacer size={1} />
              <div className="scrollable-y">
                <IonList lines="full">
                  {getConfig().payment === 'judopay' ? (
                    <IonRadioGroup
                      onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)}
                      value={this.state.selectedCard}
                    >
                      {cards.map((card, id) => {
                        const { cardLastFour, cardExpiry, cardType, name } = card;
                        let cardEx = insertIntoArray(cardExpiry, 2, '/');
                        return (
                          <IonItem key={id}>
                            <div tabIndex="-1"></div>
                            <IonLabel className="ion-text-wrap">
                              <NormalText color="primary" className="single-item bold">
                                {name || ''}
                              </NormalText>
                              <Sectiontitle className="no-margin">
                                •••• •••• •••• {cardLastFour}
                              </Sectiontitle>
                              <SmallText color="primary">
                                {__(cardType)} - {cardEx}
                              </SmallText>
                            </IonLabel>
                            <IonRadio
                              slot="start"
                              color="secondary"
                              value={id + ''}
                              onIonSelect={() => {
                                this.setState({ selectedCard: id }, () => {
                                  Basket.changeSelectedCard(id);
                                });
                              }}
                            />
                          </IonItem>
                        );
                      })}
                    </IonRadioGroup>
                  ) : (
                    <IonRadioGroup
                      onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)}
                      value={this.state.selectedCard}
                    >
                      {cards.map((card) => {
                        const { id, last4, brand, exp_month, exp_year, name } = card;
                        return (
                          <IonItem key={id}>
                            <div tabIndex="-1"></div>
                            <IonLabel className="ion-text-wrap">
                              <NormalText color="primary" className="single-item bold">
                                {name}
                              </NormalText>
                              <Sectiontitle className="no-margin">
                                •••• •••• •••• {last4}
                              </Sectiontitle>
                              {/*<SmallText color="primary">
                                {__(brand)} - {exp_month}/{exp_year}
                              </SmallText>*/}
                            </IonLabel>
                            <IonRadio
                              slot="start"
                              color="secondary"
                              value={id}
                              onIonSelect={() => {
                                this.setState({ selectedCard: id }, () => {
                                  Basket.changeSelectedCard(id);
                                });
                              }}
                            />
                          </IonItem>
                        );
                      })}
                    </IonRadioGroup>
                  )}
                </IonList>
                <IonButton
                  fill="clear"
                  color="secondary"
                  className="link underlined"
                  onClick={() =>
                    forwardTo('/card-add', {
                      addCardFromCheckout: true,
                      fromTablePay: this.props?.location?.state?.fromTablePay,
                    })
                  }
                >
                  {__('Select a differnt card')}
                </IonButton>
              </div>
              <div className="flex-min">
                {!this.props?.location?.state?.fromTablePay && (
                  <>
                    {isWeb() ? (
                      <CheckoutPay
                        clientSecret={clientSecret}
                        dispatch={dispatch}
                        __={__}
                        location={location}
                        profile={profile}
                        isBillPay={this.props?.location?.state?.fromTablePay ? true : false}
                      />
                    ) : (
                      <DrawPayButton __={__} googlePayClient={window.googlePayClient} />
                    )}
                  </>
                )}
                <IonButton
                  color
                  disabled={cards.length === 0}
                  className="customized-button"
                  onClick={() =>
                    !this.props.location?.state?.fromTablePay
                      ? Basket.createOrder()
                      : Basket.createBillPay()
                  }
                  expand="block"
                >
                  {__('Pay')}{' '}
                  {this.props.location?.state?.fromTablePay
                    ? Basket.formatPrice(
                        (Basket.table_payment_amount + Basket.bill_pay_service_charge_applied) /
                          100,
                      )
                    : Basket._getTotal()}
                </IonButton>
                {profile.is_guest && (
                  <IonButton
                    expand="block"
                    fill="clear"
                    className="link underlined"
                    color="secondary"
                    onClick={() => {
                      dispatch(logout());
                    }}
                  >
                    {__('Cancel order')}
                  </IonButton>
                )}
              </div>
            </div>
          </Layout>
        </Loading>
        <Modal
          className="delivery-time-modal"
          isOpen={deliveryTimeModalOpen}
          onDidDismiss={() => dispatch(setCommonModal('deliveryTimeModalOpen', false))}
        >
          <Title>{__('Select Collection Time')}</Title>
          <NormalText>{__('Please select different time')}</NormalText>
          <Spacer />
          <SelectOption
            className="select-delivery-time-selector"
            display="inline"
            onSet={(e, inst) => this.setPickTime(inst, minDT)}
            data={timePickerOptions}
            label="Location"
            value={pickTime}
            inputStyle="box"
            placeholder={__('Select Collection Time')}
            setText={__('OK')}
            cancelText={__('Cancel')}
            onInit={() => {
              if (timePickerOptions.length > 0) {
                const firstAvailableTime = timePickerOptions.find((i) => i.value !== null);
                if (!pickTime && firstAvailableTime && pickTime !== firstAvailableTime.value) {
                  this.changeTime(firstAvailableTime.value, minDT);
                }
              }
            }}
          />
          <Spacer />
          <IonButton
            expand="block"
            color="primary"
            className="uppercase okx-font-secondary select-delivery-time-btn"
            onClick={() => dispatch(setCommonModal('deliveryTimeModalOpen', false))}
          >
            {__('Continue')}
          </IonButton>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    cards: store.orders.cards || [],
    profile: store.profile.profile || {},
    clientSecret: store.orders.clientSecret,
    deliveryTimeModalOpen: store.common.deliveryTimeModalOpen,
    restaurants: store.restaurants.restaurants || [],
    clientSecret: store.orders.clientSecret,
  };
};

export default connect(mapStateToProps)(withTranslation(Checkout));
