import React from 'react';
import { connect } from 'react-redux';
import { IonItem, IonCol, IonGrid, IonRow, IonInput, IonButton } from '@ionic/react';
import Basket from '../../lib/basket';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import { NormalText, Spacer, StrongText } from '../../components/common';
import './index.css';
import Loading from '../../components/spinner';
import TableItemsOverview from '../../components/tableItemsOverview';
import TableBillServiceCharge from '../../components/tableBillServiceCharge';
import { forwardTo } from '../../lib/utils';
import BillPayHistory from '../../components/billPayAmount';
import BillPayAmount from '../../components/billPayAmount';
import CheckoutPay from '../checkout/CheckoutPay';
import DrawPayButton from '../../components/drawPayButton';
import { Capacitor, Plugins } from '@capacitor/core';
import { getConfig } from '../../appConfig';
const isWeb = () => Capacitor.platform === 'web';

const { SelectOption } = Mobiscroll;

class SplitBillByAmount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceChargeValue: Basket.bill_pay_service_charge || 0,
      amountToPay: (Basket.amount_left_to_pay / 100).toFixed(2) || 0,
    };
  }

  onServiceChargeSetHandler = (serviceChargeValue) => {
    this.setState({ serviceChargeValue });
  };
  componentDidMount() {
    const { google } = window;
    if (google) {
      window.googlePayClient = new google.payments.api.PaymentsClient({
        environment: 'PRODUCTION',
      });
    }
    Basket.setTablePaymentAmount(Basket.amount_left_to_pay);
  }
  componentDidUpdate(prevProps) {
    if (this.props.tableData.total_due !== prevProps.tableData.total_due) {
      Basket.setBillPayData(this.props.tableData);
      Basket.setTablePaymentAmount(this.props.tableData.total_due);
    }
  }
  setAmountToPay = (value) => {
    this.setState({ amountToPay: value }, () => {
      if (value > Basket.amount_left_to_pay / 100) {
        Basket.setTablePaymentAmount(Basket.amount_left_to_pay);
        this.setState({ amountHigher: true, amountToPay: value });
      } else {
        Basket.setTablePaymentAmount(value * 100);
        Basket.setBillPayServiceCharge(this.state.serviceChargeValue);

        this.setState({ amountHigher: false, amountToPay: value });
      }
    });
  };
  render() {
    const {
      __,
      clientSecret,
      location,
      dispatch,
      profile,
      isTableItemsOverviewVisible,
    } = this.props;
    const { serviceChargeValue } = this.state;
    const deliveryOption = Basket.getDeliveryOption();

    return (
      <Loading>
        <Layout headerTitle={__(deliveryOption ? deliveryOption.label : '')} color="transparent">
          <div className="absolute-content scrollable-y">
            <div className="bill-pay-title-wrapper">
              <NormalText className="block">
                {__('Table')} {Basket.getBillTableNumber()}
              </NormalText>
              <IonButton className="link underlined" onClick={() => forwardTo('/table-bill-pay')}>
                {__('Wrong table?')}
              </IonButton>
            </div>
            <Spacer size={1} />
            <TableItemsOverview />
            {!isTableItemsOverviewVisible && <Spacer size={1} />}
            <BillPayAmount />
            <Spacer size={1} />
            <div className="custom-amount-selector-wrapper">
              <NormalText>{__('Amount to pay')}</NormalText>
              <div className="box-wrapper  inline-box-wrapper">
                <div>
                  <StrongText>{Basket.getCurrency().label}</StrongText>
                </div>
                <IonItem lines="none" className="custom-amount-field">
                  <IonInput
                    onIonChange={(e) => this.setAmountToPay(e.target.value)}
                    max={Basket.amount_left_to_pay / 100}
                    min="0"
                    required={true}
                    type="number"
                    pattern="decimal"
                    inputmode="decimal"
                    value={this.state.amountToPay}
                  />
                </IonItem>
              </div>
            </div>

            <Spacer size={1} />
            <TableBillServiceCharge
              amountToPay={this.state.amountToPay}
              serviceChargeSet={this.onServiceChargeSetHandler}
              serviceChargeVal={Basket.bill_pay_service_charge_applied}
            />
            <Spacer size={1} />
            <>
              <IonGrid className="box-wrapper space-between pointer ">
                <IonRow>
                  <IonCol>{__('Payment Amount')}</IonCol>
                  <IonCol className="righted">{Basket.getTotalPlusServiceCharge()}</IonCol>
                </IonRow>
              </IonGrid>
              <Spacer size={1} />
            </>

            <>
              {isWeb() ? (
                <CheckoutPay
                  clientSecret={clientSecret}
                  dispatch={dispatch}
                  __={__}
                  location={location}
                  profile={profile}
                  isBillPay={true}
                  billTotal={Basket.bill_pay_total}
                />
              ) : (
                <>
                  {getConfig().billPay.hasAppleAndGPay && (
                    <DrawPayButton
                      isBillPay={true}
                      __={__}
                      googlePayClient={window.googlePayClient}
                    />
                  )}
                </>
              )}
            </>
            <IonButton
              disabled={Basket.table_payment_amount == 0}
              fill="outline"
              color="secondary"
              expand="block"
              onClick={() => forwardTo('/checkout', { fromTablePay: true })}
            >
              {__('Pay with card')}
            </IonButton>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const { restaurants, tableNumbers, tableData } = store.restaurants;
  const { myLocation, isTableItemsOverviewVisible } = store.common;
  const { clientSecret } = store.orders;
  const { auth, profile } = store.profile;

  return {
    restaurants: restaurants || [],
    tableNumbers: tableNumbers || {},
    myLocation,
    profile,
    clientSecret,
    auth,
    isTableItemsOverviewVisible,
    tableData,
  };
};

export default connect(mapStateToProps)(withTranslation(SplitBillByAmount));
