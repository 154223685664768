import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonCheckbox,
  IonTextarea,
  IonItem,
  IonList,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonButton,
  IonIcon,
  IonAlert,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import Layout from '../../components/layout';
import Incrementer from '../../components/incrementer';
import Pullup from '../../components/pullup';
import { PillGroup } from '../../components/pill';
import { SmallText, Sectiontitle, Spacer, NormalText, Title } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import {
  makeKey,
  isDefined,
  deepCopy,
  goBack,
  isWebConfig,
  isEmptyObject,
  forwardTo,
  parseAllergenData,
} from '../../lib/utils';
import { getConfig } from '../../appConfig';
import { storeItemWeb } from '../../store/actions';

import './index.css';
import BackButton from '../../components/backButton';

const {
  formatPrice,
  _calculateItemPrice,
  validateItem,
  addToBasket,
  getProductName,
  getProductDescription,
  isProductEnabled,
  isChoicesGroupValid,
  setAllergen,
} = Basket;

const noImage = () => <div className="item-details-background"></div>;

class itemDetailsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      price: 0,
      selectedChoices: [],
      validationErrors: [],
      instructions: '',
      showValidationAlert: false,
      allergensCodes: [],
    };
  }

  componentDidMount() {
    const { profile, allergens } = this.props;
    let quantity = this.state.quantity;
    const item = this.props.item;
    let selectedChoices = [];
    let validationErrors = [];

    if (item.menuDealGroups && item.menuDealGroups.length > 0) {
      selectedChoices = Array(item.menuDealGroups.length).fill([]);
      validationErrors = Array(item.menuDealGroups.length).fill(null);
    }
    let price = _calculateItemPrice({ item, quantity });
    let newArr = parseAllergenData(profile, item, allergens);
    this.setState({ price, selectedChoices, validationErrors, allergensCodes: newArr });
  }
  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantity: newQuantity,
      price: _calculateItemPrice({
        item: this.props.item,
        quantity: newQuantity,
        selectedChoices: this.state.selectedChoices,
      }),
    });
  };

  handleInputChange = (groupIndex, choiceIndex, multiSelectionPermitted, event) => {
    const item = this.props.item;
    const { quantity, selectedChoices } = this.state;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[groupIndex];

    if (selectedGroup) {
      const selectedChoice = selectedGroup.items[choiceIndex];
      let updatedSelectedChoices = deepCopy(selectedChoices);
      if (multiSelectionPermitted) {
        //checkbox
        if (event.target.checked) {
          updatedSelectedChoices[groupIndex].push(selectedChoice);
        } else {
          updatedSelectedChoices[groupIndex] = updatedSelectedChoices[groupIndex].filter(
            (i) => i.sku !== selectedChoice.sku,
          );
        }
      } else {
        //radio
        if (!selectedChoice) {
          updatedSelectedChoices[groupIndex] = [];
        } else {
          updatedSelectedChoices[groupIndex] = [selectedChoice];
        }
      }

      this.setState({ selectedChoices: updatedSelectedChoices }, () => {
        const { selectedChoices } = this.state;
        //recalculate item price on every menu deal choice change
        this.setState({ price: _calculateItemPrice({ item, quantity, selectedChoices }) }, () => {
          const validationErrors = validateItem(this.constructBasketItem());
          this.setState({ validationErrors: validationErrors.errors });
        });
      });
    }
  };

  drawGroupChoices = (choices = [], multiSelectionPermitted, groupIndex) => {
    const { selectedChoices } = this.state;
    const { profile, __ } = this.props;
    const allChoices = choices.map((item, choiceIndex) => {
      const { sku, productPrice } = item;
      const isChecked = !!(selectedChoices[groupIndex] || []).find((i) => {
        return i.sku === sku;
      });
      if (item.sku === undefined || !isProductEnabled(item)) {
        return <span key={item.sku + '_disabled'} />;
      }
      return (
        <IonItem lines="none" key={makeKey(choiceIndex, sku, groupIndex)}>
          <div tabIndex="-1"></div>
          {multiSelectionPermitted ? (
            <IonCheckbox
              color="secondary"
              slot="start"
              checked={isChecked}
              onIonChange={(event) => {
                this.handleInputChange(groupIndex, choiceIndex, multiSelectionPermitted, event);
              }}
            />
          ) : (
            <IonRadio
              slot="start"
              className="details-radio"
              color="secondary"
              value={choiceIndex}
              checked={isChecked}
            />
          )}
          <IonLabel className="ion-text-wrap">
            <Sectiontitle className="single-item item-details-product-label">
              {__(getProductName(item, profile))}
            </Sectiontitle>
            {getProductDescription(item, profile) ? (
              <SmallText className="no-margin">{getProductDescription(item, profile)}</SmallText>
            ) : null}
          </IonLabel>
          <p>{formatPrice(productPrice)}</p>
        </IonItem>
      );
    });
    if (multiSelectionPermitted) {
      return allChoices;
    } else {
      //radio
      return (
        <IonRadioGroup
          allowEmptySelection={true}
          onIonChange={(event) => {
            this.handleInputChange(groupIndex, event.target.value, multiSelectionPermitted, event);
          }}
        >
          {allChoices}
        </IonRadioGroup>
      );
    }
  };

  drawGroupLabel = (menuGroupItem, groupIndex) => {
    const { validationErrors } = this.state;
    const { __ } = this.props;
    const { description, min, max } = menuGroupItem;
    return (
      <>
        <IonItem>
          <div tabIndex="-1" className="sectiontitle">
            {description}
            <br />
            {!isDefined(min) && !isDefined(max) ? null : (
              <SmallText>
                {isDefined(min) ? __('min') + ':' + min + ',' : null}{' '}
                {isDefined(max) ? __('max') + ':' + max : null}
              </SmallText>
            )}
          </div>
        </IonItem>
        {validationErrors[groupIndex] ? (
          <div className="field-error">{__(validationErrors[groupIndex])}</div>
        ) : null}
      </>
    );
  };

  drawMenuDealGroups = (menuGroupItem, index) => {
    // multiSelectionPermitted = true  --> only one item must be selected
    const multiSelectionPermitted = menuGroupItem.multiSelectionPermitted;
    if (isChoicesGroupValid(menuGroupItem)) {
      if (isDefined(multiSelectionPermitted)) {
        return (
          <div key={index}>
            {this.drawGroupLabel(menuGroupItem, index)}
            {this.drawGroupChoices(menuGroupItem.items, multiSelectionPermitted, index)}
          </div>
        );
      } else {
        return this.drawGroupLabel(menuGroupItem);
      }
    }
  };

  constructBasketItem = () => {
    const { item } = this.props;
    const { quantity, selectedChoices, instructions } = this.state;

    return {
      item,
      quantity,
      selectedChoices,
      instructions,
    };
  };

  addToOrder = () => {
    const newBasketItem = this.constructBasketItem();
    const validationErrors = validateItem(newBasketItem);
    const item = this.props.item;

    if (validationErrors.errorCount > 0) {
      this.setState({ validationErrors: validationErrors.errors }, () => {
        this.setShowValidationAlert(true);
      });
    } else {
      if (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)) {
        this.props.dispatch(storeItemWeb(newBasketItem));
        forwardTo('/delivery-options');
      } else {
        addToBasket(newBasketItem);
        if (isWebConfig()) {
          this.props.closeModal();
        } else {
          goBack();
        }
      }
    }

    let allergensCodes =
      item &&
      item.itemRichData &&
      item.itemRichData.allergenCodes &&
      item.itemRichData.allergenCodes.length > 0
        ? item.itemRichData.allergenCodes
        : [];
    if (allergensCodes.length > 0) {
      let allergensData = [{ allergens: this.state.allergensCodes }, { sku: item.sku }];
      setAllergen(allergensData);
    }
  };

  instructionsChange = (event) => this.setState({ instructions: event.target.value });

  setShowValidationAlert = (flag) => this.setState({ showValidationAlert: flag });

  render() {
    const { __, profile, item } = this.props;
    const { quantity, showValidationAlert, allergensCodes } = this.state;
    const menuDealGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const isAddToOrderBtnValid = validateItem(this.constructBasketItem()).errorCount === 0;

    return (
      <>
        <div className="item-details-card-content">
          <IonList className="item-details-card-list">
            {/* <IonItem lines="none">
								<Sectiontitle>{ __('Description') }</Sectiontitle>
							</IonItem> */}
            <IonItem lines="none">
              <div tabIndex="-1"></div>
              <SmallText
                color="primary"
                className="item-details-card-description heading thiner-text"
                dangerouslySetInnerHTML={{
                  __html: getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, ''),
                }}
              ></SmallText>
            </IonItem>
            {allergensCodes.length > 0 ? (
              <>
                <Spacer size="1" />
                <NormalText>
                  <strong>{__('Allergens')}</strong>
                </NormalText>
                <IonItem lines="none">
                  <div tabIndex="-1"></div>
                  <PillGroup items={allergensCodes} borderColor="primary" paddingTop={0} />
                </IonItem>
              </>
            ) : null}
            {menuDealGroups.map(this.drawMenuDealGroups)}
            {getConfig().flags.specialInstructions.isDisplayed ? (
              <>
                <IonItem lines="none">
                  <div tabIndex="-1"></div>
                  <Sectiontitle>
                    {__('Special Instructions')}{' '}
                    <span className="normal-text">{__('Optional')}</span>
                  </Sectiontitle>
                </IonItem>
                <IonItem>
                  <IonTextarea
                    onIonChange={this.instructionsChange}
                    rows={1}
                    placeholder={__(getConfig().flags.specialInstructions.placeholder)}
                  ></IonTextarea>
                </IonItem>
              </>
            ) : null}
          </IonList>
        </div>
        <div className="mobile-only" style={{ height: '190px' }}></div>

        <div className="item-details-actions">
          <Incrementer
            allowNegative={false}
            quantity={quantity}
            onUpdate={this.onIncrementerUpdate}
          />
          <IonButton
            disabled={quantity === 0 || !isAddToOrderBtnValid}
            className={'item-details-add-to-order ' + (isAddToOrderBtnValid ? '' : 'greyed')}
            size="full"
            color="secondary"
            onClick={this.addToOrder}
          >
            {Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
              ? __('Start New Order')
              : __('Add to Order')}{' '}
            {/*{ price ? '-' : '' } { price }*/}
          </IonButton>
        </div>
        <IonAlert
          isOpen={showValidationAlert}
          onDidDismiss={() => this.setShowValidationAlert(false)}
          header={__('Validation')}
          message={__('Please check any required options')}
          buttons={[{ text: __('OK'), role: 'cancel', cssClass: 'secondary' }]}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    restaurantsUpdated: store.restaurants.restaurantsUpdated,
    basketUpdated: store.orders.basketUpdated,
    allergens: store.restaurants.allergens,
    storedItemWeb: store.orders.storedItemWeb,
    ikentooMenu: store.restaurants.ikentooMenu || {},
  };
};

export const ItemDetailsRaw = connect(mapStateToProps)(withTranslation(itemDetailsContent));

export class itemDetails extends Component {
  constructor(props) {
    super(props);
    this.x = React.createRef();
    this.state = {
      nameVisible: true,
    };
  }
  onContentScrollHandler() {
    if (!this.isScrolledIntoView(this.x.current)) {
      this.setState({ nameVisible: false });
    } else {
      this.setState({ nameVisible: true });
    }
  }
  isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    return isVisible;
  }
  shouldComponentUpdate(prevProps, prevState) {
    if (this.state.nameVisible !== prevState.nameVisible) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    const { __, location, history, profile } = this.props;
    const item = location.state;
    const { selectedMenu } = item;
    let image =
      item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : '';
    if (image && image.indexOf('http://') !== -1) {
      image = image.replace(/http:\/\//g, 'https://');
    }
    // const allergens = item.allergens || ['Celery (including celeriac)', 'Fish', 'Crustaceans', 'Lupin', 'Mustard']
    return (
      <Layout
        headerTitle={__('Item Details')}
        scrollY={false}
        noPadding
        contentClassName="item-details-wrapper"
        hideSecondToolbar={true}
      >
        <div className="item-details-main-wrapper">
          <div
            className={
              this.state.nameVisible ? ' item-details-header' : 'active item-details-header'
            }
          >
            <div className="item-details-back">
              <IonButton
                className="solo-button"
                color="white"
                onClick={() => forwardTo('/order', { selectedMenu })}
              >
                <IonIcon slot="icon-only" icon={arrowBack} />
              </IonButton>
            </div>
            <div
              style={{
                transition: !this.state.nameVisible ? 'transform 0.3s ease-in-out' : 'none',
                display: 'flex',
                alignItems: 'center',
                marginLeft: '20px',
                transform: this.state.nameVisible ? 'translateY(-200%)' : 'translateY(0)',
              }}
            >
              <NormalText style={{ fontSize: '18px' }}>
                <strong>{__(getProductName(item, profile))}</strong>
              </NormalText>
            </div>
          </div>
          <div
            className="scrollable-y"
            style={{ background: '#000' }}
            onScroll={() => {
              this.onContentScrollHandler();
            }}
          >
            <div className="item-details-info">
              {image && image !== '' ? (
                <div className="item-details-img">
                  <img src={image} />
                </div>
              ) : (
                <div className="image-placeholder"></div>
              )}
              <div className="item-details-name" ref={this.x}>
                <Title>{__(getProductName(item, profile))}</Title>
              </div>
              <div className="item-details-price">
                <NormalText>{formatPrice(item.productPrice)}</NormalText>
              </div>
            </div>

            <ItemDetailsRaw item={item} profile={profile} />
          </div>
        </div>
        <div className="item-details-back">
          <IonButton
            className="solo-button"
            color="white"
            onClick={() => forwardTo('/order', { selectedMenu })}
          >
            <IonIcon slot="icon-only" icon={arrowBack} />
          </IonButton>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (store) => {
  return {
    profile: store.profile.profile,
  };
};

export default connect(stateToProps)(withTranslation(itemDetails));
