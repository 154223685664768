import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonItem, IonButton, IonInput, IonGrid, IonCol, IonRow, IonIcon } from '@ionic/react';
import Basket from '../../lib/basket';
import { NormalText, Spacer, Subtitle } from '../../components/common';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { forwardTo } from '../../lib/utils';
import Loading from '../../components/spinner';
import './index.css';
import { SET_TABLE_PAY_METHOD } from '../../store/constants';
import { chevronForward } from 'ionicons/icons';
import TableBillServiceCharge from '../../components/tableBillServiceCharge';
import Modal from '../../components/modal';
import TableItemsOverview from '../../components/tableItemsOverview';
import CheckoutPay from '../checkout/CheckoutPay';
import DrawPayButton from '../../components/drawPayButton';
import { Capacitor, Plugins } from '@capacitor/core';
import { getConfig } from '../../appConfig';
import Mobiscroll from '../../components/mobiscroll';
import BillPayAmount from '../../components/billPayAmount';
const { SelectOption } = Mobiscroll;

const isWeb = () => Capacitor.platform === 'web';

class PayTableOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceChargeValue: 0,
      isSetPayMethodModalOpen: false,
      tableMembers: 0,
      payMethod: 0,
    };
  }
  componentDidMount() {
    const { google } = window;
    if (google) {
      window.googlePayClient = new google.payments.api.PaymentsClient({
        environment: 'PRODUCTION',
      });
    }
    Basket.setTablePaymentAmount(Basket.bill_total);
  }
  onPayTableClick = () => {
    forwardTo('/bill-summary');
  };
  setTablePayMethod = () => {
    this.props.dispatch({
      type: SET_TABLE_PAY_METHOD,
      data: {
        pay_method: this.state.payMethod,
        table_bill_id: Basket.bill_data.id,
        total_members_to_pay: parseInt(this.state.tableMembers),
      },
    });
  };
  onServiceChargeSetHandler = (serviceChargeValue) => {
    this.setState({ serviceChargeValue });
  };
  handleSubmit = () => {
    const { cards } = this.props;
    forwardTo('/checkout', { fromTablePay: true });
  };
  forwardToPayment = () => {
    this.handleSubmit();
  };
  formatTablePartyForSelect = () => {
    const { __ } = this.props;
    let arrForSelect = [{ text: __(`Select table members number`), value: null }];
    for (let i = 1; i <= 20; i++) {
      arrForSelect.push({
        text: `${__(`${i}`)}`,
        value: i,
      });
    }
    return arrForSelect;
  };
  render() {
    const {
      __,
      clientSecret,
      location,
      profile,
      dispatch,
      isTableItemsOverviewVisible,
    } = this.props;
    const { serviceChargeValue } = this.state;
    const deliveryOption = Basket.getDeliveryOption();
    return (
      <Loading transparent>
        <Layout headerTitle={__(deliveryOption ? deliveryOption.label : '')}>
          <div className="absolute-content scrollable-y">
            <div className="bill-pay-title-wrapper">
              <NormalText className="block">
                {__('Table')} {Basket.getBillTableNumber()}
              </NormalText>
              <IonButton className="link underlined" onClick={() => forwardTo('/table-bill-pay')}>
                {__('Wrong table?')}
              </IonButton>
            </div>

            <Spacer size={1} />
            <TableItemsOverview />
            {!isTableItemsOverviewVisible && <Spacer size={1} />}
            <BillPayAmount />
            <Spacer size={1} />
            <TableBillServiceCharge
              paymentAmount={Basket.getTableAmountToPay()}
              serviceChargeSet={this.onServiceChargeSetHandler}
            />
            <Spacer size={1} />
            {serviceChargeValue > 0 && (
              <>
                <IonGrid className="box-wrapper space-between pointer ">
                  <IonRow>
                    <IonCol>{__('Payment Amount')}</IonCol>
                    <IonCol className="righted">{Basket.getTotalPlusServiceCharge()}</IonCol>
                  </IonRow>
                </IonGrid>
                <Spacer size={1} />
              </>
            )}

            <>
              {isWeb() ? (
                <CheckoutPay
                  clientSecret={clientSecret}
                  dispatch={dispatch}
                  __={__}
                  location={location}
                  profile={profile}
                  isBillPay={true}
                  billTotal={Basket.bill_pay_total}
                />
              ) : (
                <>
                  {getConfig().billPay.hasAppleAndGPay && (
                    <DrawPayButton
                      isBillPay={true}
                      __={__}
                      googlePayClient={window.googlePayClient}
                    />
                  )}
                </>
              )}
            </>
            <IonButton
              fill="outline"
              color="secondary"
              expand="block"
              onClick={() => this.forwardToPayment()}
            >
              {__('Pay with card')}
            </IonButton>
            <NormalText className="block">{__('Want to split the bill?')}</NormalText>
            <IonGrid className="box-wrapper-multiple-items">
              <IonRow
                className="pointer"
                onClick={() => this.setState({ payMethod: 1, isSetPayMethodModalOpen: true })}
              >
                <IonCol size="10">{__('Split With Friends')}</IonCol>
                <IonCol size="2" className="righted">
                  <IonIcon icon={chevronForward} />
                </IonCol>
              </IonRow>
              <IonRow
                className="pointer"
                onClick={() => this.setState({ payMethod: 3, isSetPayMethodModalOpen: true })}
              >
                <IonCol size="10">{__('Split by Items Ordered')}</IonCol>
                <IonCol size="2" className="righted">
                  <IonIcon icon={chevronForward} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <Modal
            className={`set-pay-method-modal ${this.state.payMethod != 1 && 'small-modal'}`}
            isOpen={this.state.isSetPayMethodModalOpen}
            onDidDismiss={() => this.setState({ isSetPayMethodModalOpen: false })}
          >
            <Subtitle>{__('Are you sure you want to set this?')}</Subtitle>
            {this.state.payMethod == 1 && !Basket.getLeadUser() && (
              <>
                <Spacer />
                <NormalText>{__('Number of table members')}</NormalText>
                <IonItem lines="none" className="box-wrapper inline-box-wrapper">
                  <SelectOption
                    display="center"
                    onSet={(e, a) => this.setState({ tableMembers: a.getVal() })}
                    required={true}
                    type="number"
                    pattern="number"
                    inputmode="number"
                    data={this.formatTablePartyForSelect()}
                    value={this.state.tableMembers}
                  />
                  <div>
                    <NormalText>{__('People')}</NormalText>
                  </div>
                </IonItem>
                <Spacer />
              </>
            )}
            <IonButton
              disabled={!this.state.tableMembers > 0 && this.state.payMethod == 1}
              expand="block"
              color="secondary"
              onClick={() => this.setTablePayMethod()}
            >
              {__('Confirm')}
            </IonButton>
          </Modal>
        </Layout>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const { tableData } = store.restaurants;
  const { auth, profile } = store.profile;
  const { clientSecret } = store.orders;
  const { isTableItemsOverviewVisible } = store.common;
  return {
    tableData: tableData,
    profile: store.profile.profile,
    cards: store.orders.cards || [],
    auth,
    profile,
    clientSecret,
    isTableItemsOverviewVisible,
  };
};

export default connect(mapStateToProps)(withTranslation(PayTableOverview));
