import React from 'react';
import { connect } from 'react-redux';
import {
  IonItem,
  IonButton,
  IonAlert,
  IonCol,
  IonGrid,
  IonRow,
  IonInput,
  getConfig,
} from '@ionic/react';
import Basket from '../../lib/basket';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import { NormalText, SmallText, Spacer } from '../../components/common';
import './index.css';
import Loading from '../../components/spinner';
import { SET_TABLE_PAY_METHOD } from '../../store/constants';
import TableItemsOverview from '../../components/tableItemsOverview';
import TableBillServiceCharge from '../../components/tableBillServiceCharge';
import { forwardTo } from '../../lib/utils';
import BillPayHistory from '../../components/billPayAmount';
import BillPayAmount from '../../components/billPayAmount';
import CheckoutPay from '../checkout/CheckoutPay';
import DrawPayButton from '../../components/drawPayButton';
import { Capacitor, Plugins } from '@capacitor/core';
const isWeb = () => Capacitor.platform === 'web';

const { SelectOption } = Mobiscroll;

class SplitBill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableMembersToPay: Basket.table_members_to_pay || 0,
      serviceChargeValue: Basket.bill_pay_service_charge || 0,
      isSetPayMethodModalOpen: false,
    };
  }

  setTablePayMethod = () => {
    Basket.setBillPayServiceCharge(0);
    Basket.setPortionToPay(0);
    this.props.dispatch({
      type: SET_TABLE_PAY_METHOD,
      data: {
        pay_method: 2,
        table_bill_id: Basket.bill_data.id,
      },
    });
  };
  componentDidMount() {
    const { google } = window;
    if (google) {
      window.googlePayClient = new google.payments.api.PaymentsClient({
        environment: 'PRODUCTION',
      });
    }
  }
  selectPortion = (event, data) => {
    const tableMembersToPay = data.getVal();
    Basket.setPortionToPay(tableMembersToPay);
    this.setState({ tableMembersToPay });
  };
  formatDataForSelect = () => {
    let billMembers = 0;
    if (Basket.table_members_paid == 0) {
      billMembers = Basket.getTableMembers();
    } else {
      billMembers = Basket.table_members - Basket.table_members_paid;
    }
    const { __ } = this.props;
    let arrForSelect = [{ text: __(`Select no. of people`), value: 0 }];
    for (let i = 0; i < billMembers; i++) {
      arrForSelect.push({
        text: `${__(`${i + 1}`)} ${
          i == 0 ? __(`person`) : __(`people`)
        } - ${this.calculatePortionToPay(i + 1)}`,
        value: i + 1,
      });
    }
    return arrForSelect;
  };
  calculatePortionToPay = (members) => {
    const totalMembers = Basket.getTableMembers();
    const billTotal = Basket.bill_total / 100;
    return Basket.formatPrice(Basket.round((billTotal / totalMembers) * members, 2));
  };
  onServiceChargeSetHandler = (serviceChargeValue) => {
    this.setState({ serviceChargeValue });
  };
  render() {
    const {
      __,
      clientSecret,
      profile,
      dispatch,
      location,
      isTableItemsOverviewVisible,
    } = this.props;
    const { serviceChargeValue, tableMembersToPay } = this.state;
    const deliveryOption = Basket.getDeliveryOption();
    return (
      <Loading>
        <Layout headerTitle={__(deliveryOption ? deliveryOption.label : '')} color="transparent">
          <div className="absolute-content scrollable-y">
            <div className="bill-pay-title-wrapper">
              <NormalText className="block">
                {__('Table')} {Basket.getBillTableNumber()}
              </NormalText>
              <IonButton className="link underlined" onClick={() => forwardTo('/table-bill-pay')}>
                {__('Wrong table?')}
              </IonButton>
            </div>
            <Spacer size={1} />
            <TableItemsOverview />
            {!isTableItemsOverviewVisible && <Spacer size={1} />}
            <BillPayAmount />
            <Spacer size={1} />
            <IonGrid className="table-members-wrapper">
              <IonRow>
                <IonCol>
                  <SmallText>
                    {__('Split')} {Basket.getBillTotal()} {__('between:')}
                  </SmallText>
                  <IonItem className="box-wrapper">
                    <SelectOption
                      required={true}
                      type="number"
                      pattern="number"
                      inputmode="number"
                      value={Basket.getTableMembers()}
                      data={[
                        {
                          value: Basket.getTableMembers(),
                          text: `${Basket.getTableMembers()} ${__('people')}`,
                        },
                      ]}
                      disabled={true}
                    />
                  </IonItem>
                </IonCol>
                <IonCol>
                  <SmallText>{__('I will pay for:')}</SmallText>
                  <div
                    className={`box-wrapper ${
                      this.state.tableMembersToPay == 0 ? 'required-field' : ''
                    }`}
                  >
                    <SelectOption
                      display="center"
                      onSet={(e, a) => this.selectPortion(e, a)}
                      data={this.formatDataForSelect()}
                      value={Basket.table_members_to_pay}
                      placeholder={__('Select Potion')}
                      disabled={Basket.getTableMembers() ? false : true}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
            <Spacer size={1} />
            <TableBillServiceCharge
              tableMembers={tableMembersToPay}
              serviceChargeSet={this.onServiceChargeSetHandler}
            />
            <Spacer size={1} />
            {(serviceChargeValue > 0 || tableMembersToPay > 0) && (
              <>
                <IonGrid className="box-wrapper space-between pointer ">
                  <IonRow>
                    <IonCol>{__('Payment Amount')}</IonCol>
                    <IonCol className="righted">{Basket.getTotalPlusServiceCharge()}</IonCol>
                  </IonRow>
                </IonGrid>
                <Spacer size={1} />
              </>
            )}
            <>
              {isWeb() ? (
                <>
                  {this.state.tableMembersToPay > 0 && (
                    <CheckoutPay
                      clientSecret={clientSecret}
                      dispatch={dispatch}
                      __={__}
                      location={location}
                      profile={profile}
                      isBillPay={true}
                      billTotal={Basket.bill_pay_total}
                    />
                  )}
                </>
              ) : (
                <>
                  {getConfig().billPay.hasAppleAndGPay && (
                    <DrawPayButton
                      isBillPay={true}
                      __={__}
                      googlePayClient={window.googlePayClient}
                    />
                  )}
                </>
              )}
            </>
            <IonButton
              disabled={this.state.tableMembersToPay == 0}
              fill="outline"
              color="secondary"
              expand="block"
              onClick={() =>
                this.state.tableMembersToPay != 0
                  ? forwardTo('/checkout', { fromTablePay: true })
                  : null
              }
            >
              {__('Pay with card')}
            </IonButton>
            <IonButton
              expand="block"
              fill="clear"
              color="secondary"
              className="link underlined"
              onClick={() => this.setState({ isSetPayMethodModalOpen: true })}
            >
              {__('Or pay another amount')}
            </IonButton>
          </div>
        </Layout>
        <IonAlert
          isOpen={this.state.isSetPayMethodModalOpen}
          onDidDismiss={() => this.setState({ isSetPayMethodModalOpen: false })}
          header={__('Are you sure you want to set this?')}
          // message={__(this.getRestaurantDataMessage(__))}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.setState({ isSetPayMethodModalOpen: false }),
            },
            {
              text: __('Confirm'),
              role: 'submit',
              cssClass: 'secondary',
              handler: () => this.setTablePayMethod(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const { restaurants, tableNumbers, tableData } = store.restaurants;
  const { myLocation, isTableItemsOverviewVisible } = store.common;
  const { clientSecret } = store.orders;
  const { auth, profile } = store.profile;

  return {
    restaurants: restaurants || [],
    tableNumbers: tableNumbers || {},
    myLocation,
    tableData,
    profile,
    clientSecret,
    auth,
    isTableItemsOverviewVisible,
  };
};

export default connect(mapStateToProps)(withTranslation(SplitBill));
