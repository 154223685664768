import React from 'react';
import { IonItem, IonRow, IonCol, IonGrid, IonButton, IonIcon, IonInput } from '@ionic/react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Spacer, NormalText, FieldError } from '../../components/common';
import { connect } from 'react-redux';
import { forwardTo, validateForm } from '../../lib/utils';
import Basket from '../../lib/basket';
import { withRouter } from 'react-router';
import './index.css';
import successIcon from '../../assets/images/success-icon.svg';
import { sendTablePaymentEmail } from '../../store/actions';
import { chevronDown, chevronUp } from 'ionicons/icons';

class BillCompleted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: null,
      restaurantId: null,
      isTableItemsOverviewVisible: false,
      guestEmail: '',
      formErrors: {},
    };
    this.formConfig = {
      email: { type: 'email', required: true },
    };
  }
  sendPaymentConformationEmail = () => {
    let formErrors = validateForm(this.formConfig, { email: this.state.guestEmail });

    this.setState({ formErrors }, () => {
      if (Object.keys(formErrors).length === 0) {
        const tableDataLocalStorage = JSON.parse(localStorage.getItem('bill_data')) || {};
        const data = {
          email: this.state.guestEmail,
          id: JSON.parse(localStorage.getItem('table_payment_data')),
          amount: Basket.formatPrice(tableDataLocalStorage?.payment_amount / 100),
        };
        this.props.dispatch(sendTablePaymentEmail(data));
      }
    });
  };
  componentDidMount = () => {
    // if (isEmptyObject(this.props.billStatus)) {
    //   Basket.reset();
    //   forwardTo('/table-bill-pay');
    // }
    const tableDataLocalStorage = JSON.parse(localStorage.getItem('bill_data')) || {};

    const {
      automaticServiceChargePercentage,
      automaticServiceChargeValue,
    } = this.calculateServiceCharge(tableDataLocalStorage.paid_items);
    this.setState({ automaticServiceChargePercentage, automaticServiceChargeValue });
  };

  formatCardName = (pay_method) => {
    const usedCard = this.props.cards.find((card) => card.id === pay_method);
    if (usedCard) {
      const { brand, last4 } = usedCard;
      return `${brand} **** ${last4}`;
    } else {
      return pay_method;
    }
  };
  calculateServiceCharge = (items) => {
    let total = 0;
    let totalPlusServiceCharge = 0;
    for (let i = 0; i < items.length; i++) {
      total = total + parseFloat(items[i].grossUnitAmount);
      totalPlusServiceCharge = totalPlusServiceCharge + parseFloat(items[i].unitAmount);
      for (let j = 0; j < items[i].subLineItems.length; j++) {
        total = total + parseFloat(items[i].subLineItems[j].grossUnitAmount);
        totalPlusServiceCharge =
          totalPlusServiceCharge + parseFloat(items[i].subLineItems[j].unitAmount);
      }
    }
    if (total > 0) {
      const automaticServiceChargeValue = totalPlusServiceCharge - total;
      const num =
        ((parseFloat(totalPlusServiceCharge) - parseFloat(total)) / parseFloat(total)) * 100;
      const automaticServiceChargePercentage = `${Math.floor(num * 10) / 10}%`;
      return { automaticServiceChargePercentage, automaticServiceChargeValue };
    } else {
      return {
        automaticServiceChargePercentage: 0,
        automaticServiceChargeValue: '0%',
      };
    }
  };
  render() {
    const { __ } = this.props;
    const { isTableItemsOverviewVisible, guestEmail, formErrors } = this.state;
    const data = this.props.billStatus;
    const tableDataLocalStorage = JSON.parse(localStorage.getItem('bill_data')) || {};
    return (
      <Loading>
        <Layout headerTitle={__('Order Completed')}>
          <div className="absolute-content scrollable-y">
            <div className="centered">
              <img alt="success icon" src={successIcon}></img>
              <NormalText className="block">{__('Payment Completed - Thank You')}</NormalText>
            </div>

            <Spacer size={1} />
            <NormalText className="block">
              {__('Table')} {Basket.getBillTableNumber()}
            </NormalText>
            <Spacer size={1} />
            {tableDataLocalStorage?.paid_items.length > 0 && (
              <IonGrid
                className={`${
                  this.state.isTableItemsOverviewVisible ? 'items-overwiew-opened' : ''
                } box-wrapper space-between pointer table-items-wrapper`}
              >
                <IonRow
                  style={{ width: '100%' }}
                  onClick={() =>
                    this.setState({
                      isTableItemsOverviewVisible: !this.state.isTableItemsOverviewVisible,
                    })
                  }
                >
                  <IonCol>
                    <NormalText>
                      {this.state.isTableItemsOverviewVisible ? __('Hide Items') : __('View Items')}
                    </NormalText>
                  </IonCol>
                  <IonCol className="righted">
                    <IonIcon
                      icon={this.state.isTableItemsOverviewVisible ? chevronUp : chevronDown}
                    ></IonIcon>
                  </IonCol>
                </IonRow>
                {isTableItemsOverviewVisible && (
                  <>
                    {tableDataLocalStorage.paid_items.map((el) => (
                      <>
                        <IonRow style={{ width: '100%' }}>
                          <IonCol size="8">
                            <NormalText>{el.itemName}</NormalText>
                          </IonCol>
                          <IonCol size="4" className="righted">
                            <NormalText>{Basket.formatPrice(el.grossUnitAmount)}</NormalText>
                          </IonCol>
                        </IonRow>
                        {el.subLineItems.map((subitem, i) => (
                          <IonRow key={`sub-${i}`} style={{ width: '100%', paddingLeft: '20px' }}>
                            <IonCol size="8">
                              <NormalText>{subitem.itemName}</NormalText>
                            </IonCol>
                            <IonCol size="4" className="righted">
                              <NormalText>{Basket.formatPrice(subitem.grossUnitAmount)}</NormalText>
                            </IonCol>
                          </IonRow>
                        ))}
                      </>
                    ))}
                    {this.state.automaticServiceChargeValue > 0 && (
                      <IonRow>
                        <IonCol size="8">{`${__('Service Charge')} ${
                          this.state.automaticServiceChargePercentage
                        }`}</IonCol>
                        <IonCol size="4" className="righted">
                          {Basket.formatPrice(this.state.automaticServiceChargeValue)}
                        </IonCol>
                      </IonRow>
                    )}
                  </>
                )}
              </IonGrid>
            )}
            {!this.state.isTableItemsOverviewVisible ? <Spacer size={1} /> : null}
            <IonGrid
              className={`box-wrapper-list box-wrapper space-between pointer ${
                this.state.isTableItemsOverviewVisible ? 'items-overwiew-opened' : ''
              }`}
            >
              {tableDataLocalStorage.payment_amount ? (
                <IonRow>
                  <IonCol>{__('Items Total')}</IonCol>
                  <IonCol className="righted">
                    {Basket.formatPrice(tableDataLocalStorage?.payment_amount / 100)}
                  </IonCol>
                </IonRow>
              ) : null}
              {tableDataLocalStorage.service_charge_applied ? (
                <IonRow>
                  <IonCol>
                    {__('Tip')}{' '}
                    {tableDataLocalStorage?.service_charge_percentage
                      ? `${tableDataLocalStorage?.service_charge_percentage}%`
                      : `${__('Custom')}`}
                  </IonCol>
                  <IonCol className="righted">
                    {Basket.formatPrice(tableDataLocalStorage?.service_charge_applied / 100)}
                  </IonCol>
                </IonRow>
              ) : null}
              <IonRow>
                <IonCol size="8">
                  {__('Amount Paid')}{' '}
                  {tableDataLocalStorage.people_paying_for ? (
                    <i style={{ opacity: 0.7 }}>
                      {tableDataLocalStorage?.people_paying_for} {__('of')}{' '}
                      {tableDataLocalStorage?.total_members_to_pay} {__('people')}
                    </i>
                  ) : null}
                </IonCol>
                <IonCol size="4" className="righted">
                  {Basket.formatPrice(data?.amount / 100)}
                </IonCol>
              </IonRow>
            </IonGrid>
            <Spacer size={1} />
            <NormalText>{__('Payment')}</NormalText>
            <IonGrid style={{ marginTop: '5px' }} className="box-wrapper space-between pointer ">
              <IonRow>
                <IonCol>{this.formatCardName(tableDataLocalStorage?.payment_token)}</IonCol>
                <IonCol className="righted">{Basket.formatPrice(data?.amount / 100)}</IonCol>
              </IonRow>
            </IonGrid>
            <Spacer />
            {this.props.profile.is_guest && (
              <>
                <NormalText className="block">{__('Send Payment Confirmation')}</NormalText>
                {!this.props.paymentEmailSent ? (
                  <>
                    <IonItem lines="none" style={{ marginTop: '5px' }} class="box-wrapper">
                      <IonInput
                        value={guestEmail}
                        onIonChange={(e) => this.setState({ guestEmail: e.target.value })}
                        placeholder={__('Email Address for Payment Confirmation')}
                      />
                    </IonItem>
                    {formErrors.email ? (
                      <FieldError className="field-error" value={__(formErrors.email)} />
                    ) : null}
                  </>
                ) : (
                  <>
                    <div className="payment-confirmation-wrapper">
                      <img alt="success icon" src={successIcon}></img>
                      <NormalText>
                        {__('Your Payment Confirmation has been sent to')}{' '}
                        <b>{this.state.guestEmail}</b>
                      </NormalText>
                    </div>
                  </>
                )}
                <Spacer size={1} />
                {!this.props.paymentEmailSent && (
                  <IonButton
                    onClick={() => this.sendPaymentConformationEmail()}
                    fill="outline"
                    color="secondary"
                    expand="block"
                  >
                    {__('Send Email')}
                  </IonButton>
                )}
              </>
            )}
            <IonButton
              fill="outline"
              color="secondary"
              expand="block"
              onClick={() => {
                Basket.reset();
                localStorage.removeItem('bill_data');
                forwardTo('/table-bill-pay');
              }}
            >
              {__('Go Back')}
            </IonButton>
            <IonButton
              fill="clear"
              color="secondary"
              className="underlined link"
              expand="block"
              onClick={() => {
                Basket.reset();
                forwardTo('/feedback', {
                  fromBillPay: true,
                  selectedRestaurant: tableDataLocalStorage?.pos_location_id,
                });
              }}
            >
              {__('Leave Feedback')}
            </IonButton>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const orders = store.orders;
  const { restaurants, tableData } = store.restaurants;
  const { paymentEmailSent } = store.common;
  let lastOrder = null;
  const orderHistory = orders.orderHistory;
  if (orderHistory && orderHistory.length > 0) {
    lastOrder = orderHistory[0];
  }
  const { profile } = store.profile;
  return {
    lastOrder,
    cards: orders.cards || [],
    billStatus: orders.billStatus || {},
    restaurants: restaurants || [],
    tableData: tableData,
    profile,
    paymentEmailSent,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(BillCompleted)));
