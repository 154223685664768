import { IonButton, IonInput, IonItem, IonModal } from '@ionic/react';
import React, { Component } from 'react';
import { getConfig } from '../../appConfig.js';
import Basket from '../../lib/basket.js';
import { withTranslation } from '../../lib/translate.js';
import { NormalText, Spacer, Title } from '../common/index.js';
import Modal from '../modal/index.js';
import './index.css';

class TableBillServiceCharge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      service_charge: Basket.bill_pay_service_charge || 0,
      service_charge_applied: 0,
      billPayCustomServiceChargeModalOpen: false,
      custom_service_charge: 0,
    };
  }
  componentDidMount() {
    if (this.state.service_charge !== null) {
      Basket.setBillPayServiceCharge(this.state.service_charge);
    }
    this.props.serviceChargeSet(this.state.service_charge);
  }
  setBillPayCustomServiceCharge(value) {
    Basket.setBillPayServiceCharge(null, value * 100);
    this.setState(
      {
        custom_service_charge: value * 100,
        billPayCustomServiceChargeModalOpen: false,
        service_charge: null,
      },
      () => {
        this.props.serviceChargeSet(value * 100);
      },
    );
  }
  setBillPayServiceCharge(e, service_charge) {
    e.stopPropagation();
    document
      .querySelectorAll('.bill-pay-service-charge-button')
      .forEach((el) => el.classList.remove('is-active'));
    e.target.classList.add('is-active');
    Basket.setBillPayServiceCharge(service_charge);
    this.setState({ service_charge }, () => {
      this.props.serviceChargeSet(service_charge);
    });
  }

  render() {
    const { __ } = this.props;
    return (
      <>
        <div className="bill-pay-service-charge-wrapper">
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <NormalText>{__('Tip')}</NormalText>
            <NormalText>{Basket.getBillPayServiceChargeValue()}</NormalText>
          </div>
          <div>
            {getConfig().general.billPayServiceChargeValues.map((el, index) => {
              return (
                <IonButton
                  key={index}
                  className={`bill-pay-service-charge-button ${
                    this.state.service_charge == el && 'is-active'
                  }`}
                  onClick={(e) => this.setBillPayServiceCharge(e, el)}
                  color="secondary"
                >
                  {el == 0 ? <>{__('No, thanks')}</> : <>{el}%</>}
                </IonButton>
              );
            })}
          </div>
          <Spacer size={1} />
          <div>
            <IonButton
              expand="block"
              fill="clear"
              color="primary"
              className="link underlined small-button"
              onClick={(e) => this.setState({ billPayCustomServiceChargeModalOpen: true })}
            >
              {__('Custom Amount')}
            </IonButton>
            <IonButton
              expand="block"
              fill="clear"
              color="primary"
              className="link underlined small-button"
              onClick={(e) => this.setBillPayServiceCharge(e, 0)}
            >
              {__('No thanks')}
            </IonButton>
          </div>
        </div>
        {this.state.billPayCustomServiceChargeModalOpen && (
          <Modal
            className="custom-service-charge-modal"
            onDidDismiss={() => this.setState({ billPayCustomServiceChargeModalOpen: false })}
            isOpen={this.state.billPayCustomServiceChargeModalOpen}
          >
            <Title>{__('Add tip')}</Title>
            <Spacer size={1} />
            <IonItem lines="none" className="box-wrapper">
              <IonInput
                onIonChange={(e) => this.setState({ serviceChargeAmount: e.target.value })}
                type="number"
                pattern="number"
                inputmode="number"
                placeholder={__('Tip')}
              ></IonInput>
            </IonItem>
            <IonButton
              disabled={!this.state.serviceChargeAmount || this.state.serviceChargeAmount == 0}
              color="secondary"
              expand="block"
              onClick={() => this.setBillPayCustomServiceCharge(this.state.serviceChargeAmount)}
            >
              {__('Add Tip')}
            </IonButton>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation(TableBillServiceCharge);
