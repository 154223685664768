import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  IonItem,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonButton,
  IonAlert,
  IonLabel,
  IonCheckbox,
} from '@ionic/react';
import Basket from '../../lib/basket';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import { getRestaurants, getRestaurantTable } from '../../store/restaurants/actions';
import { registerRequest, setFullRegisterForm, showToast } from '../../store/actions';
import { forwardTo, goBack, isEmptyArray, isEmptyObject } from '../../lib/utils';
import { NormalText, Spacer, StrongText, Title } from '../../components/common';
import './index.css';
import Loading from '../../components/spinner';
import { SET_TABLE_DATA } from '../../store/constants';

const { SelectOption } = Mobiscroll;

class PayTableBill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurant: null,
      tables: [],
      table: null,
      restaurantValue: null,
      tableValue: null,
      isSubmitRestaurantAlertOpen: false,
      showTableNumber: true,
      selectedRestaurant: null,
      isForwardToBillPayAlertOpen: false,
      isLocationAllowed: false,
    };
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const { restaurants, dispatch, myLocation } = this.props;
    const filteredRestaurants = restaurants.filter((restaurant) => restaurant.can_bill_pay);
    if (this.state.isLocationAllowed || (myLocation?.latitude && myLocation?.longitude)) {
      filteredRestaurants.sort(function (a, b) {
        return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
      });
    } else {
      filteredRestaurants.sort(function (a, b) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
    }
    this.setState({ selectedRestaurant: filteredRestaurants[0] });
    !isEmptyArray(restaurants) && dispatch(getRestaurants());
    if (searchParams.get('bl_id')) {
      const selectedRestaurant = this.props.restaurants.find(
        (restaurant) => restaurant.business_location_id == searchParams.get('bl_id'),
      );
      this.setState({
        selectedRestaurant,
        tables: this.props.tableNumbers[selectedRestaurant.pos_location_id],
        showTableNumber: true,
        useSearchParams: true,
      });
      if (searchParams.get('t_id')) {
        this.setState(
          {
            tableValue: searchParams.get('t_id'),
          },
          () => {
            // Basket.setRestaurant(this.state.selectedRestaurant);
            // Basket.setTableNumber(this.state.tableValue);
            // this.forwardToBillPay();
          },
        );
      }
    }
    Basket.reset();
    Basket.setOrderType('bill-pay');
  }
  position = async () => {
    await navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setState({
          isLocationAllowed: true,
        });
      },
      (err) => console.log(err),
    );
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedRestaurant !== this.state.selectedRestaurant) {
      if (!isEmptyObject(this.props.tableNumbers)) {
        this.setState({
          tables: this.props.tableNumbers[this.state.selectedRestaurant.pos_location_id],
        });
      }
    }
  }

  handleRestaurantSelection = (e, a) => {
    if (a.getVal() !== null) {
      this.setState({
        restaurant: this.props.restaurants.find((el) => el.business_location_id == a.getVal()),
        restaurantValue: a.getVal(),
        tableValue: null,
      });
    }
  };

  formatMobiSelect = (flag) => {
    const { __ } = this.props;
    const { tables } = this.state;
    let listForSelect = [];
    // Format for restaurants selection
    if (flag === 'restaurant') {
      if (!isEmptyArray(this.props.restaurants)) {
        this.props.restaurants.map((restaurant, index) => {
          listForSelect.push({ text: restaurant.name, value: restaurant.business_location_id });
        });
      }
      return [{ text: __('Select Restaurants'), value: null }, ...listForSelect];
    } else {
      if (!isEmptyArray(tables)) {
        tables.map((table, index) => {
          listForSelect.push({ text: table.description, value: table.number });
        });
      }
      return [{ text: __('Select table'), value: null }, ...listForSelect];
    }
  };

  onClickNext = () => {
    const { tableValue, selectedRestaurant } = this.state;
    const { dispatch } = this.props;
    if (!tableValue) {
      dispatch(showToast('Choose table', 'danger'));
      return;
    }
    Basket.setRestaurant(selectedRestaurant);
    Basket.setTableNumber(tableValue);
    this.setState({ isForwardToBillPayAlertOpen: true });
  };
  forwardToBillPayAsGuest = () => {
    const { tableValue, selectedRestaurant } = this.state;
    Basket.setRestaurant(selectedRestaurant);
    Basket.setTableNumber(tableValue);
    const { dispatch } = this.props;
    const timestamp = new Date().getTime();
    const data = {
      email: `${timestamp}_guest@5loyalty.com`,
      first_name: 'guest_user',
      mobile: 0,
      accepted_terms_and_conditions: true,
      guest: true,
      password: timestamp,
    };
    this.props.dispatch(setFullRegisterForm(data));
    dispatch(getRestaurantTable(selectedRestaurant, tableValue, true));
  };
  forwardToBillPay = async () => {
    const { tableValue, selectedRestaurant } = this.state;
    const { dispatch } = this.props;
    dispatch({ type: SET_TABLE_DATA, key: 'tableData', value: {} });
    dispatch(getRestaurantTable(selectedRestaurant, tableValue));
  };

  getRestaurantDataMessage = (__) => {
    return ` <p><b>${__('Table')}:</b> ${Basket.getTableNumber()}</p> \n <p><b>${__(
      'Location',
    )}:</b> ${Basket.getRestauranName()}</p> `;
  };
  getRestautantDataMessage = (restaurant) => {
    return `<p><b>${restaurant?.name}</b></p> \n <p>${restaurant?.address}</p>`;
  };
  formatTableNumberForSelect = (selectedRestaurant) => {
    let arrForSelect = [];
    if (selectedRestaurant) {
      this.props.tableNumbers[selectedRestaurant.pos_location_id].map((el) => {
        arrForSelect.push({ html: `<span>${el.description}</span>`, value: el.number });
      });
    }
    return [...arrForSelect];
  };
  backHandler = () => {
    if (!this.state.showTableNumber) {
      this.setState({ showTableNumber: true });
    } else {
      goBack();
    }
  };
  render() {
    const { __, auth } = this.props;
    const { restaurantValue, tables, tableValue, showTableNumber, selectedRestaurant } = this.state;
    const deliveryOption = Basket.getDeliveryOption();
    const { loggedIn } = auth;
    return (
      <Loading>
        <Layout
          hideSecondToolbar={showTableNumber}
          backHandler={this.backHandler}
          headerTitle={__(deliveryOption ? deliveryOption.label : '')}
          color="transparent"
        >
          <div className="absolute-content scrollable-y">
            {showTableNumber ? (
              <>
                <NormalText>{__('Select your location and table')}</NormalText>
                <Spacer />
                <IonItem
                  className="default-padding box-wrapper unselectable"
                  key={selectedRestaurant?.id}
                  lines="none"
                >
                  <div tabIndex="-1"></div>
                  <IonCheckbox color="secondary" slot="start" checked />
                  <IonLabel className="ion-text-wrap" color="dark">
                    <StrongText>{selectedRestaurant?.name}</StrongText>
                    <NormalText className="block">{selectedRestaurant?.address}</NormalText>
                  </IonLabel>
                </IonItem>
                <IonButton
                  expand="block"
                  fill="clear"
                  color="secondary"
                  className="link underlined"
                  onClick={() =>
                    this.setState({
                      showTableNumber: false,
                    })
                  }
                >
                  {__('At a different location?')}
                </IonButton>
                <Spacer />
                {this.formatTableNumberForSelect(this.state.selectedRestaurant).length > 0 && (
                  <>
                    <NormalText>{__('Select Table Number')}</NormalText>
                    <div className="order-to-table-picker-wrapper">
                      <SelectOption
                        cssClass="click-collect-time-wrapper"
                        display="inline"
                        label="Table Number"
                        inputStyle="box"
                        setText={__('OK')}
                        cancelText={__('Cancel')}
                        data={this.formatTableNumberForSelect(this.state.selectedRestaurant)}
                        value={this.state.tableValue}
                        onSet={(e, a) => {
                          this.setState({ tableValue: a.getVal() });
                        }}
                        onInit={() => {
                          const tables = this.formatTableNumberForSelect(
                            this.state.selectedRestaurant,
                          );
                          if (tables.length > 0 && tableValue == null) {
                            const firstTable = tables[0];
                            this.setState({ tableValue: firstTable.value });
                          }
                        }}
                      />
                    </div>
                  </>
                )}
                <Spacer size={1} />
                <IonButton
                  disabled={this.state.tableValue == null}
                  color="secondary"
                  expand="block"
                  fill="outline"
                  onClick={() =>
                    this.props.auth.loggedIn
                      ? this.onClickNext()
                      : forwardTo('/login', { fromTablePay: true })
                  }
                >
                  {loggedIn ? __('Continue') : __('Already Have An Account? Log In')}
                </IonButton>
                {!loggedIn && (
                  <IonButton
                    fill="outline"
                    disabled={this.state.tableValue == null}
                    color="secondary"
                    expand="block"
                    onClick={() => this.onClickNext()}
                  >
                    {__('Continue As Guest')}
                  </IonButton>
                )}
              </>
            ) : (
              <div className="pay-table-select-restaurant">
                <NormalText>{__('Select different location')}</NormalText>
                <Spacer />
                <div className="restaurants-wrapper">
                  {this.props.restaurants
                    .filter((restaurant) => restaurant.can_bill_pay)
                    .map((store) => (
                      <IonItem
                        disabled={store.opened === 'Closed'}
                        onClick={() => this.setState({ selectedRestaurant: store })}
                        lines="none"
                        className="default-padding box-wrapper"
                      >
                        <IonLabel>
                          <StrongText>{__(store.name)}</StrongText>
                          <NormalText className="block">{__(store.address)}</NormalText>
                        </IonLabel>
                        <IonCheckbox
                          checked={selectedRestaurant && store.id === selectedRestaurant.id}
                          slot="start"
                          color="secondary"
                        />
                      </IonItem>
                    ))}
                </div>
                <IonButton
                  onClick={() => this.setState({ isSubmitRestaurantAlertOpen: true })}
                  expand="block"
                  color="secondary"
                >
                  {__('Select restaurant')}
                </IonButton>
              </div>
            )}
          </div>
          <IonAlert
            isOpen={this.state.isForwardToBillPayAlertOpen}
            onDidDismiss={() => this.setState({ isForwardToBillPayAlertOpen: false })}
            header={__('Please check your table')}
            message={__(this.getRestaurantDataMessage(__))}
            buttons={[
              {
                text: __('Cancel'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => this.setState({ isForwardToBillPayAlertOpen: false }),
              },
              {
                text: __('Confirm'),
                role: 'submit',
                cssClass: 'secondary',
                handler: () =>
                  this.props.auth?.loggedIn
                    ? this.forwardToBillPay()
                    : this.forwardToBillPayAsGuest(),
              },
            ]}
          />
        </Layout>
        <IonAlert
          isOpen={this.state.isSubmitRestaurantAlertOpen}
          onDidDismiss={() => this.setState({ isSubmitRestaurantAlertOpen: false })}
          header={__('Please confirm you are in this location')}
          message={__(this.getRestautantDataMessage(selectedRestaurant))}
          buttons={[
            {
              text: this.state.forwardToOrdering ? __('Change') : __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () =>
                this.state.forwardToOrdering
                  ? this.setState({ showTableNumber: false, forwardToOrdering: false })
                  : this.setState({ isSubmitRestaurantAlertOpen: false }),
            },
            {
              text: __('Confirm'),
              role: 'submit',
              cssClass: 'secondary',
              handler: () =>
                this.state.forwardToOrdering
                  ? this.chooseMenusForLocation()
                  : this.setState({ selectedRestaurant, showTableNumber: true }),
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const { restaurants, tableNumbers } = store.restaurants;
  const { myLocation } = store.common;
  const { profile, auth } = store.profile;
  return {
    restaurants: restaurants || [],
    tableNumbers: tableNumbers || {},
    myLocation,
    profile,
    auth,
  };
};

export default connect(mapStateToProps)(withTranslation(PayTableBill));
